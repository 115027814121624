import React from 'react'
import styles from '../venueItemAlt.module.scss'
import { motion, AnimatePresence } from 'framer-motion';
import classNames from 'classnames';

const VenueItemAltShimmer = () => {
    return (
        <div
            className={classNames(styles['container'])}>
            <div className={classNames(styles['image_item'], styles['shimmer_bg'])} style={{ height: '14.5rem' }}>

            </div>
            <div className={classNames(styles['row'], styles['align-start'], styles['justify-space-between'], styles['mt-20'])} style={{ marginBottom: 16 }}>
                <div style={{ width: 240, height: 25, borderRadius: 4, marginBottom: 16, background: '#202226' }} />
            </div>
            <div className={classNames(styles['row'], styles['align-center'], styles['mb-24'])}>
                <div style={{ width: 179, height: 18, borderRadius: 4, background: '#202226' }} />
            </div>
            <div className={classNames(styles['row'], styles['mt-8'], styles['align-center'], styles['borderTop'], styles['pt-16'], styles['mb-32'])}>
                <div className={styles['shimmer_bg']}>

                </div>
            </div>

        </div>

    )
}

export default VenueItemAltShimmer