import React, { useCallback, useImperativeHandle, forwardRef, useState, useEffect, useMemo } from 'react'
import styles from './CitiesOperated.module.scss';
import classNames from 'classnames';
import Link from 'next/link';
import { useRouter } from 'next/router';
import { capitalise } from '@/helper/capitalise';
import { pageType } from '@/routes/venues/venues';
import { AllCitiesType, getAllCities } from '@/apis/getAllCities';

interface CitiesOperatedPropType {
    reload: boolean;
    city: string;
    area: string;
    Pagetype: pageType;
}

const CitiesOperated = (({ reload, city, area, Pagetype }: CitiesOperatedPropType, ref: React.Ref<unknown> | undefined) => {

    const router = useRouter();
    const [sports, setSports] = useState<string[]>([]);
    const [cities, setCities] = useState<AllCitiesType[]>([]);

    const getcities = useCallback(async () => {
        const response: AllCitiesType[] = await getAllCities();
        setCities(response);
    }, []);

    useEffect(() => {
        getcities();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    const setData = useCallback((sportsData: string[]) => {
        setSports(sportsData);
    }, [])

    useImperativeHandle(ref, () => ({
        setData,
    }));

    const handleClick = useCallback(() => {
        if (reload) {
            setTimeout(() => {
                router.reload()
            }, 500);
        }

    }, [reload, router]);

    const LinkItem = useCallback((title: string, link: string, dot: boolean) => {
        return (
            <div className={classNames(styles['row'], styles['align-center'], styles['mb-16'], styles['mr-16'])}>
                <Link onClick={handleClick} className={classNames(styles['sub_4'], styles['white2_5'], styles['deco-none'], styles['link_cont'])} href={link}>{title}</Link>
            </div>
        )
    }, [handleClick]);

    const getCourt = useCallback((sport: string) => {
        switch (sport) {
            case 'football':
                return 'Football venues';
            case 'badminton':
                return 'Badminton courts';
            case 'cricket':
                return 'Cricket grounds';
            case 'basketball':
                return 'Basketball courts';
            case 'swimming':
                return 'Swimming pools';
            case 'pickleball':
                return 'Pickleball courts';
            case 'padel':
                return 'Padel courts';
            case 'squash':
                return 'Squash courts';
            case 'tennis':
                return 'Tennis courts';
            default:
                return '';
        }
    }, [])


    const renderLinks = useCallback((item: AllCitiesType) => {
        const firstlist = item?.sports?.length > 4 ? item?.sports?.slice(0, 4) : item?.sports;

        return (
            <div className={styles['mb-40']}>
                <p className={classNames(styles['title_2'], styles['white1'], styles['mb-16'])}>{capitalise(item?.city)}</p>
                <div className={classNames(styles['row'], styles['align-center'], styles['wrap'])}>
                    <div className={classNames(styles['list_cont'])}>
                        {LinkItem(`Sports venues in ${capitalise(item?.city)}`, `/${item?.city?.toLocaleLowerCase()}/sports-venues`, true)}
                        {item?.sports?.map((sport, index) => {
                            return (
                                <>
                                    {LinkItem(`${getCourt(sport)} in ${capitalise(item?.city)}`, `/${item?.city?.toLocaleLowerCase()}/${sport}-sports-venues`, index < firstlist?.length - 1)}
                                </>
                            );
                        })}
                    </div>
                </div>
            </div>
        )
    }, [LinkItem, getCourt])

    const getSportUrl = useCallback((sport: string) => {
        switch (sport) {
            case 'football':
                return 'football-turfs-near-me';
            case 'badminton':
                return 'badminton-courts-near-me';
            case 'cricket':
                return 'cricket-grounds-near-me';
            case 'basketball':
                return 'basketball-courts-near-me';
            case 'swimming':
                return 'swimming-pools-near-me';
            case 'pickleball':
                return 'pickleball-courts-near-me';
            case 'padel':
                return 'padel-courts-near-me';
            case 'squash':
                return 'squash-courts-near-me';
            case 'tennis':
                return 'tennis-courts-near-me';
            default:
                return '';
        }
    }, [])



    return (
        <div className={styles['container']}>
            <div className={styles['sub_container']}>
                <p className={classNames(styles['title_1'], styles['white1'], styles['mb-40'])}>Cities we operate in</p>
                <>
                    {cities.map((item, index) => {
                        return (<div key={index}>
                            {renderLinks(item)}
                        </div>)
                    })}

                </>

                {sports?.length > 0 && <div className={classNames(styles['mt-48'], styles['mb-40'])}>
                    <p className={classNames(styles['title_1'], styles['white1'], styles['mb-40'])}>Sports in {Pagetype == pageType?.AREA ? `${capitalise(area.replaceAll('-', ' '))}, ${capitalise(city)}` : `${capitalise(city)}`}</p>
                    <div className={classNames(styles['list_cont'])}>
                        {sports.map((item, index) => {
                            let url = '';
                            if (Pagetype == pageType?.AREA) {
                                url = `/${city}/${area}/${getSportUrl(item)}`
                            } else {
                                url = `/${city}/${item}-sports-venues`
                            }
                            return (<div key={index}>
                                {LinkItem(capitalise(item), url, sports?.length - 1 !== index)}
                            </div>)
                        })}

                    </div>
                </div>}
            </div>

        </div>
    )
})

export default forwardRef(CitiesOperated)