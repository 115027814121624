import React, { useEffect, useState } from 'react';
import Image from 'next/image';
import { motion, AnimatePresence } from 'framer-motion';
//styles
import styles from './discount.module.scss';
import classNames from 'classnames';
import Landing_modal from '../landing_modal';

import scan from '../../../../public/landingimages/Turfqr.png';
import apple from '../../../../public/landingimages/appleplaystores.svg';
import play from '../../../../public/landingimages/playstoreicons.png';
import close from '../../../../public/landingimages/popupclose1.svg';
import GetTurfTownModal from '../GetTurfTownModal';
import {
    isAndroid,
    isIOS
} from "react-device-detect";

const DiscountOnApp = () => {

    const [isOpen, setIsOpen] = useState(false);
    const [showModal, setModal] = useState<boolean>(false);
    const [isMobileView, setIsMobileView] = useState(true);

    useEffect(() => {
        setTimeout(() => {
            setIsOpen(true)
        }, 2000)
    }, [])

    const toggleOpen = () => {
        setIsOpen(false);
    };

    useEffect(() => {
        checkViewport();
        window.addEventListener('resize', checkViewport);
        return () => {
            window.removeEventListener('resize', checkViewport);
        };
    }, []);


    const checkViewport = () => {
        const isMobileView = window.matchMedia('(max-width: 767px)').matches;
        setIsMobileView(isMobileView);
    };

    const handleDownload = () => {

        if (isAndroid) {
            window.location.href =
                "https://play.google.com/store/apps/details?id=com.turftown&hl=en_US";
        } else if (isIOS) {
            window.location.href =
                "https://onelink.to/turftown";
        } else {
            // setModal(true)
            window.location.href =
                "https://turftown.in";
        }
    }

    if (isMobileView) return (
        <>
            {/* <AnimatePresence>
                {isOpen && (<motion.div
                    initial={{ height: 0 }}
                    animate={{ height: 97, transition: { duration: 0.5, ease: 'easeInOut' } }}
                    exit={{ height: 0, transition: { delay: 0.2, duration: 0.5, ease: 'easeInOut' } }}
                    className={classNames(styles['row'], styles['justify-space-between'], styles['align-center'], styles['top_container'])}>
                    {isOpen && (<motion.div

                        initial={{ opacity: 0 }}
                        animate={{ opacity: 1, transition: { delay: 0.2, duration: 0.5, ease: 'easeInOut' } }}
                        exit={{ opacity: 0, transition: { duration: 0.5, ease: 'easeInOut' } }}
                        className={classNames(styles['row'], styles['align-center'])}>
                        <Image priority onClick={handleDownload} width={24} height={42} src={'/icons/mobile-phone.png'} alt={'mobile'} />
                        <span onClick={handleDownload} className={classNames(styles['Body'], styles['white1'], styles['ml-16'])}>Download the Turf Town app &
                            start playing   <Image style={{ marginBottom: -2 }} height={12} width={13} src={'/icons/white_right_arrow.png'} alt={'logo'} /></span>


                    </motion.div>)}

                    {isOpen && (
                        <motion.div
                            initial={{ opacity: 0 }}
                            animate={{ opacity: 1, transition: { delay: 0.2, duration: 0.5, ease: 'easeInOut' } }}
                            exit={{ opacity: 0, transition: { duration: 0.5, ease: 'easeInOut' } }}>

                            <Image priority onClick={toggleOpen} width={26} height={26} src={'/svgs/remove.svg'} alt={'toggle_icon'} />
                        </motion.div>)}
                </motion.div>)}
            </AnimatePresence> */}
        </>
    )

    return (
        <>
            <AnimatePresence>
                {isOpen && (<motion.div
                    className={styles['container']}
                    initial={{ y: '100%' }}
                    animate={{ y: 0, transition: { duration: 1, ease: 'easeInOut' } }}
                    exit={{ y: '200%', transition: { duration: 1, ease: 'easeInOut' } }}
                >
                    <Image
                        onClick={toggleOpen}
                        className={classNames(styles['close_button'])}
                        height={30}
                        width={30}
                        src={'/icons/remove_button.png'}
                        alt={'logo'}
                    />
                    <p className={classNames(styles['title'], styles['mb-16'])}>
                        Get discounts by booking on the App!
                    </p>
                    <div onClick={() => { setModal(true) }} className={classNames(styles['button'], styles['mb-16'])}>
                        <p className={styles['button_text']}>Get Turf Town</p>
                    </div>
                    <Image
                        width={110}
                        height={135}
                        src={'/icons/placeholder_mobile.png'}
                        alt={'logo'}
                    />
                </motion.div>)}
            </AnimatePresence>
            <Landing_modal show={showModal} onClose={() => setModal(false)}>
                <div className="modal-dialog modal-lg modal-dialog-centered" >
                    <div className="modal-content" id='modal-content'>
                        <div className={classNames(styles.modal_header)}>
                            <div className={styles.modal_title}>A game is around the
                                corner,
                                <div style={{ textAlign: "left" }}> scan this QR code.</div></div>
                            <div >
                                <Image src={close} className={styles.closebutton} alt="close"
                                    onClick={() => setModal(false)} />
                            </div>
                        </div>
                        <div className={classNames(styles.modal_body)}>
                            <div className={styles.modal_body_scan}>
                                <ul className={classNames(styles.modal_list)}>
                                    <li className='mb-1.5 lg:mb-3 list-disc listdisc' style={{ color: "#B4B4B8", fontFamily: "Nexa-TextBook" }}>Open your phone camera and point it at the QR code.</li>
                                    <li className='mb-1.5 lg:mb-3 list-disc listdisc' style={{ color: "#B4B4B8", fontFamily: "Nexa-TextBook" }}>Alternatively, download any QR code scanner to scan.</li>
                                    <li className='mb-1 list-disc listdisc' style={{ color: "#B4B4B8", fontFamily: "Nexa-TextBook" }}>Click on the link generated to download Turf Town.</li>
                                </ul>
                                <div><Image src={scan} className={styles.scanimages} alt="qr" loading='lazy' /></div>
                            </div>
                        </div>
                        <div >
                            <div className={classNames(styles.modal_playstore_icons)}>
                                <a href='https://apps.apple.com/in/app/turf-town/id1490231308' target="__blank" aria-label='Read more about Seminole tax hike'>  <Image src={apple} alt="apple_icon" className={styles.mobileapple} loading='lazy' /></a>
                                <a href='https://play.google.com/store/apps/details?id=com.turftown' target="__blank" aria-label='Read more about Seminole tax hike'> <Image src={play} className={styles.mobileandroid} alt="google_icon" loading='lazy' /></a></div>
                        </div>
                    </div>
                </div>
            </Landing_modal>
        </>

    );
};

export default DiscountOnApp;
