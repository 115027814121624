import { SvgPropType } from '@/types/svgType'
import React from 'react'

const PriceIcon = ({ fill = "#7B818C" }: SvgPropType) => {
    return (
        <div >
            <svg width="22" height="16" viewBox="0 0 22 16" fill="none" >
                <g clip-path="url(#clip0_1412_33677)">
                    <path d="M5.13247 0.0464876C5.13539 0.122396 5.13997 0.198305 5.13997 0.274213C5.13997 4.40386 5.13997 8.53294 5.13997 12.6615V12.8925C5.22339 12.8996 5.24758 12.8304 5.28679 12.7912C5.67133 12.4112 6.05546 12.0304 6.43334 11.6438C6.51425 11.5604 6.55846 11.5562 6.64188 11.6438C6.98917 12.0019 7.34174 12.3551 7.69959 12.7032C7.78885 12.7899 7.77383 12.8354 7.69375 12.9151C6.75991 13.8435 5.83191 14.7781 4.89598 15.7045C4.49183 16.1049 3.97841 16.094 3.5705 15.6895C2.66183 14.785 1.75398 13.8795 0.846976 12.973C0.800263 12.9263 0.768983 12.8571 0.691406 12.8479V12.7645C0.738578 12.6721 0.799937 12.5876 0.873252 12.5142C1.15478 12.2369 1.43089 11.9545 1.71325 11.6801C1.90552 11.4916 1.97184 11.4907 2.16245 11.6767C2.47984 11.9866 2.79015 12.3024 3.10504 12.6156C3.15384 12.6636 3.19305 12.7228 3.26395 12.752C3.32026 12.6852 3.29106 12.6068 3.29106 12.5363C3.29273 8.49151 3.2937 4.44654 3.29398 0.401422C3.29398 0.276298 3.25519 0.135326 3.36572 0.0260507C3.92252 0.0152067 4.47932 0.0260507 5.03612 0.0214629C5.05303 0.018365 5.07042 0.0189995 5.08706 0.023322C5.1037 0.0276446 5.1192 0.0355508 5.13247 0.0464876Z" fill={fill} />
                    <path d="M15.7744 3.57484C13.7707 3.57484 11.7671 3.57596 9.76342 3.57818C9.60368 3.57818 9.55196 3.54398 9.55488 3.37631C9.56781 2.91252 9.56531 2.4479 9.55488 1.98369C9.55196 1.83562 9.58033 1.78516 9.74382 1.78516C13.7723 1.79044 17.8005 1.79044 21.8287 1.78516C21.9651 1.78516 22.0018 1.81977 21.9993 1.95532C21.9909 2.4333 21.9876 2.91169 21.9993 3.38924C22.0039 3.55316 21.9505 3.57693 21.8028 3.57693C19.7936 3.57276 17.7841 3.57206 15.7744 3.57484Z" fill={fill} />
                    <path d="M14.4423 7.1174C12.8828 7.1174 11.3234 7.1174 9.76348 7.12073C9.60749 7.12073 9.55035 7.09154 9.55494 6.92053C9.56787 6.45007 9.5637 5.97877 9.55494 5.50789C9.55494 5.37776 9.57997 5.32812 9.72177 5.32812C12.8757 5.3323 16.0295 5.3323 19.1832 5.32812C19.3021 5.32812 19.3346 5.36274 19.333 5.47952C19.3271 5.97126 19.3242 6.463 19.333 6.95473C19.3363 7.09988 19.2846 7.12157 19.1574 7.11948C17.5875 7.11614 16.0158 7.11545 14.4423 7.1174Z" fill={fill} />
                    <path d="M13.0899 10.6764C11.9813 10.6764 10.8722 10.6735 9.76364 10.6802C9.60432 10.6802 9.54926 10.6472 9.5551 10.477C9.56803 10.0062 9.56344 9.53528 9.5551 9.06439C9.5551 8.93593 9.57804 8.88672 9.72193 8.88672C11.9883 8.89172 14.2545 8.89172 16.5203 8.88672C16.635 8.88672 16.6734 8.91174 16.6717 9.0327C16.6646 9.52443 16.6634 10.0162 16.6717 10.5079C16.6742 10.6468 16.6325 10.6789 16.4982 10.6785C15.3629 10.6743 14.2264 10.6764 13.0899 10.6764Z" fill={fill} />
                    <path d="M11.7891 14.2191C11.103 14.2191 10.4165 14.2158 9.7304 14.2216C9.59902 14.2216 9.5523 14.1941 9.55481 14.0548C9.56398 13.5701 9.56398 13.0853 9.55481 12.6004C9.55189 12.4561 9.60277 12.4336 9.73206 12.4336C11.1043 12.4378 12.4766 12.4378 13.8491 12.4336C13.9742 12.4336 14.0121 12.4624 14.01 12.5925C14.0017 13.0838 14.0017 13.5756 14.01 14.0677C14.0126 14.2003 13.9721 14.2258 13.8474 14.225C13.1613 14.2158 12.4752 14.2191 11.7891 14.2191Z" fill={fill} />
                </g>
                <defs>
                    <clipPath id="clip0_1412_33677">
                        <rect width="21.3094" height="16" fill="white" transform="translate(0.691406)" />
                    </clipPath>
                </defs>
            </svg>

        </div>
    )
}

export default PriceIcon