import React, { useCallback } from 'react';
import 'react-loading-skeleton/dist/skeleton.css';
import { motion, AnimatePresence } from 'framer-motion';
import styles from './venueList.module.scss'
import classNames from 'classnames';
import VenueItemAltShimmer from '../venueItemAlt/veneuItemAltShimmer/VenueItemAltShimmer';

function LoaderItem() {

    const item = useCallback(() => {
        return (
            <VenueItemAltShimmer />
        )
    }, []);

    return (

        <>
            {item()}
            {item()}
            {item()}
        </>
    );
}

export default LoaderItem;
