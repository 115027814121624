import React, { useCallback, useState, useMemo, useEffect } from 'react'
//styles
import styles from './sportVenueList.module.scss'
import classNames from 'classnames'
import { SportVenueListType } from './type'
import Filters from '../../venues/filters';
import { customSort } from '@/helper/customSort';
import LoaderItem from '../../venues/venueList/loaderItem';
import ExtraFilter, { selectedObjectType } from './extraFilter';
import { Venue } from '@/routes/locate-venues/types';
import { VenueListSportBody, getVenueListWithSportLoc } from '@/apis/getVenueListWithSportLoc';
import VenueItemAlt from '../../venues/venueItemAlt';
import { useRouter } from 'next/router';
import { getVenuesCityConfig } from '@/apis/getVenuesCityConfig';
import { pageType } from '@/routes/venues/venues';
import PrimaryButton from '@/components/common/primaryButton';
import { capitalise } from '@/helper/capitalise';
import { useIsMobile } from '@/helper/useIsMobile';

const SportVenueList = (({ selectedSport, city, area, type }: SportVenueListType) => {
    const isMobile = useIsMobile();
    const [newConfig, setConfig] = useState<any[]>([]);
    const [feedList, setFeedList] = useState<Venue[]>([]);
    const [filter, selectFilter] = useState('Distance');
    const [hasMore, setHasMore] = useState<boolean>(true);
    const [loading, setLoading] = useState<boolean>(true);
    const [filterLoading, setFilterLoading] = useState<boolean>(false);
    const [selected, setSelected] = useState<selectedObjectType>({});
    const [count, setCount] = useState<number>(0);


    const fetch = useCallback(async (fresh: boolean, isMobile: boolean) => {

        try {
            setLoading(true);
            const venue_body: VenueListSportBody = {
                sport: selectedSport as string,
                limit: isMobile ? 8 : 24,
                skip: fresh ? 0 : feedList.length,
                city: city ? city.replaceAll('-', ' ') : '',
                area: type === pageType.AREA ? area ? area.replaceAll('-', ' ') : '' : '',
                latLong: [],
                venue_type: [],
            };
            const response = await getVenueListWithSportLoc(venue_body);
            if (fresh) {
                const configRes = await getVenuesCityConfig(venue_body);
                setConfig(configRes ? configRes : []);
            }

            if (response?.venues?.length > 0) {
                if (fresh) {
                    setFeedList(response?.venues);
                    setCount(response?.total_venues);
                } else {
                    setFeedList([...feedList, ...response?.venues]);
                }

                if (response?.venues?.length < (isMobile ? 8 : 24)) {
                    setHasMore(false);
                } else {
                    setHasMore(true);
                }
            } else {
                setHasMore(false);
            }
            setTimeout(() => {
                setLoading(false);
            }, 1000)


        } catch (e) {
            setLoading(false);
        }

        // }
    }, [area, city, feedList, selectedSport, type])

    useEffect(() => {
        if (selectedSport) {
            fetch(true, isMobile);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [selectedSport, city, area, isMobile])


    const venueWithOffers = useMemo(() => {
        return feedList.filter((i) => i?.offers && i?.offers?.length > 0)
    }, [feedList])


    const venueWithPrice = useMemo(() => {
        return customSort(feedList, (a, b) => Number(a?.configuration?.avg_price) - Number(b?.configuration?.avg_price))
    }, [feedList])


    const data: Venue[] = useMemo(() => {
        if (filter == 'Relevance') {
            //return feedList;
            return feedList;
        } else if (filter == 'Offers') {
            return venueWithOffers;
        } else if (filter == 'Distance') {
            return feedList;
        } else if (filter == 'Price') {
            return venueWithPrice;
        } else {
            return feedList;
        }
    }, [filter, feedList, venueWithOffers, venueWithPrice])

    const venueWithFormat = useMemo(() => {
        return data.filter((i) => {
            if (i?.configuration?.types && i?.configuration?.types?.length > 0) {
                for (const type of i?.configuration?.types) {
                    if (type in selected) return i;
                }
            }
        })
    }, [data, selected])

    const renderItem = useCallback(() => {
        if (Object.keys(selected).length > 0) return (
            <>
                {venueWithFormat?.map((i, index) => {
                    return <VenueItemAlt key={index} data={i} />
                })}
            </>
        )
        return (
            <>
                {data?.map((i, index) => {
                    return <VenueItemAlt key={index} data={i} />
                })}
            </>
        )
    }, [data, selected, venueWithFormat])

    const setFilterWithShimmer = useCallback((name: string) => {
        setFilterLoading(true);
        selectFilter(name);
        setTimeout(() => {
            setFilterLoading(false);
        }, 800)
    }, [])

    const setExtraFilterWithShimmer = useCallback((obj: selectedObjectType) => {
        setFilterLoading(true);
        setSelected(obj);
        setTimeout(() => {
            setFilterLoading(false);
        }, 800)
    }, [])


    const getcourtType = useCallback((sport: String) => {
        switch (sport.toLocaleLowerCase()) {
            case 'badminton':
            case 'pickleball':
            case 'basketball':
            case 'padel':
            case 'tennis':
            case 'squash':
                return count > 1 ? 'Courts' : 'Court';
            case 'cricket':
                return count > 1 ? 'Grounds' : 'Ground';
            case 'swimming':
                return count > 1 ? 'Pools' : 'Pool';
            case 'football':
                return count > 1 ? 'Turfs' : 'Turf';
            default:
                return '';
        }
    }, [count])

    return (
        <>
            <Filters title={`${count} ${selectedSport} ${getcourtType(selectedSport)} in ${type === pageType.AREA ? `${capitalise(area ? area : '')}, ${capitalise(city ? city : '')} ` : `${capitalise(city ? city : '')}`}`} onChange={setFilterWithShimmer} loading={(loading && feedList?.length == 0)} >
                <ExtraFilter config={newConfig} onApply={setExtraFilterWithShimmer} />
            </Filters>
            {(filterLoading || (loading && feedList?.length == 0)) && <div className={classNames(styles['row'], styles['wrap'], styles['mt-56'], styles['container'])}>
                <LoaderItem />
            </div>}
            {!filterLoading && <div
                className={classNames(styles['row'], styles['wrap'], styles['mt-56'], styles['container'])}
            >
                {renderItem()}
                {loading && <LoaderItem />}
            </div>}
            {!loading && !filterLoading && hasMore && feedList?.length != 0 && (
                <div className={classNames(styles['row'], styles['align-center'], styles['justify-center'])}>
                    <a className={styles.button_cont} onClick={() => {
                        fetch(false, isMobile);
                    }}>
                        <PrimaryButton
                            text='See more'
                            onPress={() => {
                                // fetch(false);
                            }}
                        />
                    </a>
                </div>
            )}
        </>

    )
})

export default SportVenueList;


