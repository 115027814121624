import React, { useCallback, useEffect, useMemo, useState } from 'react'
//styles
import styles from './venueList.module.scss'
import classNames from 'classnames'
import { VenueListType } from './type'
import Filters from '../filters'
import { Venue } from '@/routes/locate-venues/types'
import { getVenuesAroundYou, getVenuesAroundYouBody } from '@/apis/getVenuesAroundYou'
import LoaderItem from './loaderItem'
import { customSort } from '@/helper/customSort'
import VenueItemAlt from '../venueItemAlt'
import { pageType } from '@/routes/venues/venues'
import PrimaryButton from '@/components/common/primaryButton'
import { capitalise } from '@/helper/capitalise'
import { useIsMobile } from '@/helper/useIsMobile'



const VenueList = ({ city, area, type }: VenueListType) => {
    const isMobile = useIsMobile();
    const [feedList, setFeedList] = useState<Venue[]>([]);
    const [hasMore, setHasMore] = useState<boolean>(true);
    const [loading, setLoading] = useState<boolean>(false);
    const [filterLoading, setFilterLoading] = useState<boolean>(true);
    const [filter, selectFilter] = useState('Distance');
    const [count, setCount] = useState<number>(0);

    useEffect(() => {
        setFilterLoading(true)
        setTimeout(() => {
            setFilterLoading(false);
        }, 800)
    }, [])

    const fetch = useCallback(async (fresh: boolean, isMobile: boolean) => {
        try {
            setLoading(true);
            let body: getVenuesAroundYouBody = {
                city: city ? city.replaceAll('-', ' ') : '',
                area: type === pageType.AREA ? area ? area.replaceAll('-', ' ') : '' : '',
                latLong: [],
                limit: isMobile ? 8 : 24,
                skip: fresh ? 0 : feedList?.length,
                sport: ["cricket", "football", "baseketball", "badminton", "swimming", "pickleball"]
            }
            const response = await getVenuesAroundYou(body);

            setCount(response?.data?.all_venues);
            if (response?.data?.final_venues?.length > 0) {
                if (fresh) {
                    setFeedList(response?.data?.final_venues);
                } else {
                    setFeedList([...feedList, ...response?.data?.final_venues]);
                }

                if (response?.data?.final_venues?.length < (isMobile ? 8 : 24)) {
                    setHasMore(false);
                } else {
                    setHasMore(true);
                }
            } else {
                setHasMore(false);
            }
            setTimeout(() => {
                setLoading(false);
            }, 1000)

        } catch (e) {
            setLoading(false);
        }


    }, [area, city, feedList, type])

    useEffect(() => {
        fetch(true, isMobile);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [city, area, isMobile])

    const venueWithOffers = useMemo(() => {
        return feedList.filter((i) => i?.offers && i?.offers?.length > 0)
    }, [feedList])

    const venueWithDistance = useMemo(() => {
        return customSort(feedList, (a, b) => parseFloat(a.distance) - parseFloat(b.distance))
    }, [feedList])

    const venueWithPrice = useMemo(() => {
        return customSort(feedList, (a, b) => Number(a?.configuration?.avg_price) - Number(b?.configuration?.avg_price))
    }, [feedList])


    const data = useMemo(() => {
        if (filter == 'Relevance') {
            //return feedList;
            return feedList;
        } else if (filter == 'Offers') {
            return venueWithOffers;
        } else if (filter == 'Distance') {
            return feedList;
        } else if (filter == 'Price') {
            return venueWithPrice;
        } else {
            return feedList;
        }
    }, [filter, feedList, venueWithOffers, venueWithPrice])

    const renderItem = useCallback(() => {
        return (
            <>
                {data?.map((i, index) => {
                    return <VenueItemAlt key={index} data={i} index={index} />
                })}
            </>
        )
    }, [data])

    const setFilterWithShimmer = useCallback((name: string) => {
        setFilterLoading(true);
        selectFilter(name);
        setTimeout(() => {
            setFilterLoading(false);
        }, 800)
    }, [])

    return (
        <>
            <Filters title={`${count} Sports ${count > 1 ? 'Venues' : 'Venue'} in ${type === pageType.AREA ? `${capitalise(area ? area : '')}, ${capitalise(city ? city : '')}` : `${capitalise(city ? city : '')}`}`} onChange={setFilterWithShimmer} loading={(loading && feedList?.length == 0)} />
            {(filterLoading || (loading && feedList?.length == 0)) && <div className={classNames(styles['row'], styles['wrap'], styles['mt-56'], styles['container'])}>
                <LoaderItem />
            </div>}
            {!filterLoading && <div
                className={classNames(styles['row'], styles['wrap'], styles['mt-56'], styles['container'])}
            >
                {renderItem()}
                {loading && <LoaderItem />}

            </div>}
            {!loading && !filterLoading && hasMore && feedList?.length != 0 && (
                <div className={classNames(styles['row'], styles['align-center'], styles['justify-center'])}>
                    <a className={styles.button_cont} onClick={() => {
                        fetch(false, isMobile);
                    }}>
                        <PrimaryButton
                            text='See more'
                            onPress={() => {
                                // fetch(false);
                            }}
                        />
                    </a>
                </div>
            )}
        </>

    )
}

export default VenueList;


