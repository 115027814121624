import React, { useCallback, useImperativeHandle, forwardRef, useState, useMemo, useEffect } from 'react'
import styles from './popularLocalities.module.scss'
import classNames from 'classnames';
import LocalitieItem from '../LocalitieItem/LocalitieItem';
import { OperatedCitiesType } from '@/apis/getOperatedCities';
import { capitalise } from '@/helper/capitalise';
import { ExplorepageType } from '@/routes/exploreComponent/exploreComponent';
import { useRouter } from 'next/router';
import { AreaType } from '@/apis/getCityAreas';
import { getNearByLocalities, NearByAreasBodyType } from '@/apis/getNearByLocalities';
import { pageType } from '@/routes/venues/venues';
interface PopularLocalitiesPropType {
    type: ExplorepageType;
    area: string;
    city: string;
    Pagetype: pageType;
}

const PopularLocalities = (({ type, area, city, Pagetype }: PopularLocalitiesPropType, ref: React.Ref<unknown> | undefined) => {
    const router = useRouter()
    const [data, set] = useState<{ popularAreas: OperatedCitiesType[]; selectedCity: OperatedCitiesType } | undefined>();
    const [areas, setAreas] = useState<AreaType[]>([]);

    const fetchAreas = useCallback(async () => {
        try {
            let body: NearByAreasBodyType = {
                area: area
            }
            const response = await getNearByLocalities(body);
            let modifiedAreas = response?.length > 8 ? response?.splice(0, 7) : response;
            modifiedAreas = modifiedAreas.filter((i) => i?.locality_name?.toLocaleLowerCase() != area?.replaceAll('-', ' ').toLocaleLowerCase())
            // let sortedAreas = sortAreas(response)
            setAreas(modifiedAreas);
        } catch (e) {
            console.error('fetchAreas error', e)
        }
    }, [area])

    useEffect(() => {
        if (Pagetype == pageType.AREA) {
            fetchAreas();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [Pagetype])

    const setData = useCallback((popularAreas: OperatedCitiesType[], selectedCity: OperatedCitiesType) => {
        set({ popularAreas: popularAreas, selectedCity: selectedCity });
    }, [])

    useImperativeHandle(ref, () => ({
        setData,
    }));

    const modifiedData = useMemo(() => {
        if (data) {
            return data?.popularAreas?.filter((i) => i?.locality_name?.toLocaleLowerCase() != area?.replaceAll('-', ' ').toLocaleLowerCase());
        } else {
            return [];
        }

    }, [area, data]);



    const reload = useCallback(() => {
        setTimeout(() => {
            router?.reload()
        }, 600)
    }, [router])

    if (!data || modifiedData?.length == 0) return <></>

    if (Pagetype == pageType.AREA) {
        if (areas?.length == 0) return null;
        return (
            <div className={styles['container']} id='PopularLocalities'>
                <div className={styles['sub_container']}>
                    <h2 className={classNames(styles['title_1'], styles['white1'])}>Popular localities near {capitalise(area)}, {capitalise(city)}</h2>
                    <div className={classNames(styles['mt-30'], styles['row'], styles['align-center'], styles['wrap'], styles['list_container'])}>
                        {areas?.map((item, index) => {
                            let url = '';
                            if (type == ExplorepageType.VENUES) {
                                url = `/${data?.selectedCity?.city.trim().toLocaleLowerCase().replaceAll(' ', '-')}/${item?.locality_name.trim().toLocaleLowerCase().replaceAll(' ', '-')}/sports-venues`;
                            } else if (type == ExplorepageType.GAMES) {
                                url = `/game-list/${data?.selectedCity?.city.trim().toLocaleLowerCase().replaceAll(' ', '-')}/${item?.locality_name.trim().toLocaleLowerCase().replaceAll(' ', '-')}`;
                            }
                            return (
                                <LocalitieItem key={index} title={item?.locality_name} venueCount={0} link={url} />
                            )
                        })}

                    </div>
                </div>
            </div>
        )
    }

    return (
        <div className={styles['container']} id='PopularLocalities'>
            <div className={styles['sub_container']}>
                <h2 className={classNames(styles['title_1'], styles['white1'])}>Popular localities in {capitalise(data?.selectedCity?.city)}</h2>
                <div className={classNames(styles['mt-30'], styles['row'], styles['align-center'], styles['wrap'], styles['list_container'])}>
                    {modifiedData?.map((item, index) => {
                        let url = '';
                        if (type == ExplorepageType.VENUES) {
                            url = `/${data?.selectedCity?.city.trim().toLocaleLowerCase().replaceAll(' ', '-')}/${item?.locality_name.trim().toLocaleLowerCase().replaceAll(' ', '-')}/sports-venues`;
                        } else if (type == ExplorepageType.GAMES) {
                            url = `/game-list/${data?.selectedCity?.city.trim().toLocaleLowerCase().replaceAll(' ', '-')}/${item?.locality_name.trim().toLocaleLowerCase().replaceAll(' ', '-')}`;
                        }
                        return (
                            <LocalitieItem key={index} title={item?.locality_name} venueCount={0} link={url} />
                        )
                    })}

                </div>
            </div>
        </div>
    )
})


export default forwardRef(PopularLocalities);
