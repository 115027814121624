import classNames from 'classnames';
import React, { useCallback, useEffect, useState, useMemo, useRef } from 'react';
import { GetServerSideProps } from 'next';
//styles
import styles from './venues.module.scss';
//components
import NavBar from '@/components/common/NavBar';
import Sports from '@/components/pageComponents/venues/sports/sports';
import VenueList from '@/components/pageComponents/venues/venueList';
import NearVenueSearch from '@/components/pageComponents/venueLocation/nearVenueSearch';
import VenueGpsSearch from '@/components/pageComponents/venues/venueGpsSearch/venueGpsSearch';
import SportVenueList from '@/components/pageComponents/sportVenues/venueList/sportVenueList';

import Footer from '@/components/common/Footer';
import DiscountOnApp from '@/components/common/DiscountOnApp';
import { motion } from 'framer-motion'
import Image from 'next/image';
import Seo from '@/components/common/seo/Seo';
import { BASEURL } from '@/utils/baseUrl';
import { useRouter } from 'next/router';
import { ExplorepageType } from '../exploreComponent/exploreComponent';
import PopularLocalities from '@/components/common/PopularLocalities/popularLocalities';
import CitiesOperated from '@/components/common/CitiesOperated';
import InfoFooter from '@/components/common/InfoFooter';
import { OperatedCitiesType } from '@/apis/getOperatedCities';
import { capitalise } from '@/helper/capitalise';
import { VenueData } from '../venue/types';



export enum pageType {
    CITY,
    AREA,
    NOSERVICE,
}

interface VenuesPropType {
    Pagetype: pageType;
    city: string | null;
    area: string | null;
    sport: string | null;
    url: string | null;
    notValid: boolean | null;
    // data: VenuePropType | ErrorPropType | SingleVenuePropType;
}

const Venues = ({ city, area, sport, Pagetype, url, notValid }: VenuesPropType) => {
    const popularcitiesRef = useRef<null | { setData: (popularAreas: OperatedCitiesType[], selectedCity: OperatedCitiesType) => void, }>();

    const citiesOperatedRef = useRef<null | { setData: (data: string[]) => void }>();
    const router = useRouter();
    const [selectedSport, setSelectedSport] = useState(sport ? sport : '');

    useEffect(() => {
        if (notValid) {
            router.push('/404');
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [notValid]);

    const outOfBound = useCallback(() => {
        let cityName = city ? city : area;
        return (
            <>
                <motion.div
                    exit={{ opacity: 0, transition: { duration: 0.7, ease: 'easeOut' } }}
                    animate={{ opacity: 1, transition: { duration: 0.7, ease: 'easeIn' } }}
                    initial={{ opacity: 0 }} className={classNames(styles['App1'], styles['p-0'])}>
                    <NavBar leftComponent={<VenueGpsSearch city={Pagetype == pageType.CITY ? city ? city : '' : area ? area : ''} type={ExplorepageType.VENUES} cityName={city ? city : ''} sendData={(popularAreas: OperatedCitiesType[], selectedCity: OperatedCitiesType) => { }} />} />

                    <div
                        className={classNames(
                            styles['container'],
                            styles['column'],
                            styles['align-center']
                        )}
                    >
                        <div
                            className={classNames(styles['sub_container'])}
                        >
                            <div className={classNames(styles['sub_header'])}>

                                <div className={classNames(styles['search_container'])}> <VenueGpsSearch city={Pagetype == pageType.CITY ? city ? city : '' : area ? area : ''} type={ExplorepageType.VENUES} cityName={city ? city : ''} sendData={(popularAreas: OperatedCitiesType[], selectedCity: OperatedCitiesType) => { }} /></div>

                            </div>

                        </div>
                        <Image className={styles['outB_image']} width={215} height={155} src={'/images/outOfBound.png'} alt={'out of bound logo'} />

                        <p className={classNames(styles['large_title'], styles['white4'], styles['align_text_center'], styles['mt-40'])}>
                            Out of bounds!
                        </p>
                        <p className={classNames(styles['outB_text'])}>
                            We are currently not available at this location. We’ll be there soon
                        </p>
                    </div>
                </motion.div>
                <Footer />
            </>
        )
    }, [Pagetype, area, city])

    const { title, desc, heading } = useMemo(() => {

        let title, desc, heading, middleText = '';
        let cityName = Pagetype == pageType.CITY ? city : area;
        if (cityName == null) {
            cityName = ''
        }

        switch (selectedSport) {
            case 'football':
                middleText = 'Football Turfs'
                break;
            case 'badminton':
                middleText = 'Badminton Courts'
                break;

            case 'basketball':
                middleText = 'Basketball Courts'
                break;

            case 'cricket':
                middleText = 'Cricket Grounds'
                break;

            case 'pickleball':
                middleText = 'Pickleball Courts'
                break;
            case 'swimming':
                middleText = 'Swimming Pools'
                break;

            case 'padel':
                middleText = 'Padel Courts'
                break;
            case 'squash':
                middleText = 'Squash Courts'
                break;
            case 'tennis':
                middleText = 'Tennis Courts'
                break;

            default:
                middleText = '';
                // title = `Sports Venue Booking in ${capitalise(cityName)} | Grounds Near Me - Turf Town`;
                // desc = `Unlock the best sports venues across ${capitalise(cityName)} with the Turf Town App. From cricket to football, find and book your ideal sports ground near you. Whether it's for practice, matches, or events, experience seamless booking and elevate your game today with Turf Town.`;

                break;

        }

        let areaText = Pagetype == pageType.CITY ? `${capitalise(city ? city : '')}` : `${capitalise(area ? area : '')}, ${capitalise(city ? city : '')}`
        if (selectedSport) {
            title = `${middleText} in ${areaText} | Book Online with Turf Town`
            desc = `Discover and book the best ${middleText} in ${areaText} with Turf Town. Check hourly prices, read reviews, compare ratings and book online with ease.`
            heading = `Book Sports Venue In ${areaText} - Quick & Easy Online Reservations`;
        } else {
            title = `Sports Venues in ${areaText} | Book Online with Turf Town`;
            desc = `Discover and book the best sports venues across ${areaText} with Turf Town. Check hourly prices, read reviews, compare ratings and book online with ease.`;
            heading = `Book Sports Venue In ${areaText} - Quick & Easy Online Reservations`;
        }


        return { title, desc, heading }
    }, [Pagetype, area, city, selectedSport])

    const breadcrumbList = useMemo(() => {
        return {
            "@context": "https://schema.org",
            "@type": "BreadcrumbList",
            "itemListElement": [{
                "@type": "ListItem",
                "position": 1,
                "item": {
                    "@id": "https://turftown.in/",
                    "name": "Home",
                },
            }, {
                "@type": "ListItem",
                "position": 2,
                "item": {
                    "@id": "https://turftown.in/sports-venues",
                    "name": "Sport Venues",
                }
            }, {
                "@type": "ListItem",
                "position": 2,
                "item": {
                    "@id": `https://turftown.in${router?.asPath}`,
                    "name": "Venue Listing",
                }
            }]

        }
    }, [router?.asPath]);


    const renderCityVenues = useCallback(() => {
        return (
            <>
                <Seo
                    meta_title={title}
                    description={desc}
                    heading={heading}
                    url={router?.asPath ? `${BASEURL}${router?.asPath}` : `${BASEURL}${url}`}
                    breadcrumbList={JSON.stringify(breadcrumbList)}
                />
                <motion.div
                    exit={{ opacity: 0, transition: { duration: 0.3, ease: 'easeOut' } }}
                    animate={{ opacity: 1, transition: { duration: 0.3, ease: 'easeIn' } }}
                    initial={{ opacity: 0 }}
                    className={classNames(styles['App1'], styles['p-0'])}>
                    <DiscountOnApp />
                    <NavBar leftComponent={
                        <VenueGpsSearch
                            city={Pagetype == pageType.CITY ? city ? city : '' : area ? area : ''}
                            type={ExplorepageType.VENUES} cityName={city ? city : ''}
                            sendData={(popularAreas: OperatedCitiesType[], selectedCity: OperatedCitiesType) => {
                                if (popularcitiesRef?.current) {
                                    popularcitiesRef?.current?.setData(popularAreas, selectedCity)
                                }
                            }} />
                    } />
                    <>

                        <div
                            className={classNames(
                                styles['container'],
                                styles['column'],
                                styles['align-center']
                            )}
                        >
                            <div
                                className={classNames(styles['sub_container'])}
                            >
                                <div className={classNames(styles['sub_header'])}>
                                    <div className={classNames(styles['search_container'])}>
                                        <VenueGpsSearch
                                            city={Pagetype == pageType.CITY ? city ? city : '' : area ? area : ''}
                                            type={ExplorepageType.VENUES}
                                            cityName={city ? city : ''}
                                            sendData={(popularAreas: OperatedCitiesType[], selectedCity: OperatedCitiesType) => {
                                                if (popularcitiesRef?.current) {
                                                    popularcitiesRef?.current?.setData(popularAreas, selectedCity)
                                                }
                                            }} />
                                    </div>
                                    <div className={styles['searchBar_width']}>
                                        <NearVenueSearch />
                                    </div>
                                </div>
                                <Sports selectedSport={selectedSport} onChange={(sport) => { setSelectedSport(sport); }} city={city ? city : ''} area={area ? area : ''} onData={(sports: string[]) => {
                                    if (citiesOperatedRef?.current) {
                                        citiesOperatedRef?.current?.setData(sports);
                                    }
                                }} />

                                {!selectedSport && (
                                    <>
                                        <VenueList city={city} type={Pagetype} area={area} />
                                    </>
                                )}

                                {selectedSport && (
                                    <>
                                        <SportVenueList selectedSport={selectedSport} city={city} type={Pagetype} area={area} />
                                    </>
                                )}
                            </div>
                        </div>

                    </>

                </motion.div>
                <PopularLocalities ref={popularcitiesRef} type={ExplorepageType.VENUES} area={area ? area : ''} city={city ? city : ''} Pagetype={Pagetype} />
                <InfoFooter city={city ? city : ''} sport={selectedSport} area={area ? area : ''} Pagetype={Pagetype} />
                <CitiesOperated Pagetype={Pagetype} city={city ? city : ''} area={area ? area : ''} ref={citiesOperatedRef} reload />
                <Footer />
            </>
        );
    }, [Pagetype, area, breadcrumbList, city, desc, heading, router?.asPath, selectedSport, title, url])


    const renderPage = useCallback(() => {
        if (Pagetype == pageType.NOSERVICE) {
            return (<>{outOfBound()}</>)

        } else {
            return (<>{renderCityVenues()}</>)
        }

    }, [Pagetype, outOfBound, renderCityVenues])

    return (
        <>
            {renderPage()}
        </>
    );
};


export const getServerSideProps: GetServerSideProps = async (props) => {
    const { query, req } = props;
    let Pagetype: pageType = pageType.CITY;
    let sports = ['football', 'badminton', 'basketball', 'cricket', 'pickleball', 'swimming', 'squash', 'tennis', 'padel'];
    const city = query?.city as string;
    const area = query?.area as string;
    const sportname = query?.sportname as string;
    let isSport: string | undefined = '';
    let notValid: boolean | undefined = false;

    if (sportname) {
        Pagetype = pageType.AREA;
        let splitvalue = sportname.split('-');
        isSport = sports.find((i) => i == splitvalue[0]);
        if (sportname.includes('sports-venues') || sportname.includes('near-me')) {
            notValid = false;
        } else {
            notValid = true;
        }
    } else if (area && !sportname) {
        Pagetype = pageType.CITY;
        let splitvalue = area.split('-');
        isSport = sports.find((i) => i == splitvalue[0]);
        if (area.includes('sports-venues')) {
            notValid = false;
        } else {
            notValid = true;
        }
    } else {
        notValid = true;
    }
    let body: { city?: string, area?: string } = {
        city: city,
        area: Pagetype == pageType.AREA ? area : '',
    }

    try {


        const response = await fetch('https://api.turftown.in/api/v2/venue/venue_exists', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(body),
        })
        const res: { status: string, data: boolean } = await response.json()

        if (!res?.data) {
            Pagetype = pageType.NOSERVICE
        }
    } catch (e) {

        Pagetype = pageType.NOSERVICE
    }

    return {
        props: {
            Pagetype: Pagetype,
            city: city ? city : null,
            area: area ? area : null,
            sport: isSport ? isSport : null,
            url: req?.url ? req?.url : null,
            notValid: notValid ? notValid : null,
        },
    };
}


export default Venues;
