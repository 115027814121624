import React, { useCallback, useMemo, useState, useEffect } from 'react'
//styles
import styles from './sportVenueList.module.scss';
//component
import FilterItem from '../../venues/filters/filterItem';
import RadioButton from '@/components/common/radio/radio';
import classNames from 'classnames';
import { configType } from '@/apis/getVenuesCityConfig';
import Landing_modal from '@/components/common/landing_modal';
import Modal from '@/components/common/modal';
import PrimaryButton from '@/components/common/primaryButton';
import { ButtonType } from '@/components/common/primaryButton/types';

export interface selectedObjectType {
    [key: string]: string;
}

interface extraFilterType {
    config: configType[];
    onApply: (obj: selectedObjectType) => void;
}

const ExtraFilter = ({ config, onApply }: extraFilterType) => {

    const [open, setOpen] = useState(false);
    const [applied, setApplied] = useState(false);
    const [selected, setSelected] = useState<selectedObjectType>({});

    const [isMobileView, setIsMobileView] = useState(true);

    useEffect(() => {
        checkViewport();
        window.addEventListener('resize', checkViewport);
        return () => {
            window.removeEventListener('resize', checkViewport);
        };
    }, []);


    const checkViewport = () => {
        const isMobileView = window.matchMedia('(max-width: 767px)').matches;
        setIsMobileView(isMobileView);
    };

    const listItem = useCallback((title: String, selected: boolean, onClick: () => void) => {
        return (
            <div onClick={onClick} className={classNames(styles['row'], styles['align-center'], styles['justify-space-between'], styles['mb-20'], styles['cursor_pointer'])}>
                <p className={classNames(styles['Body'], styles['white1'])}>{title}</p>
                <RadioButton value={selected} onClick={() => { }} />
            </div>
        )
    }, []);


    const rightComponent = useCallback(() => {
        let length = Object.keys(selected).length
        if (!applied) return <></>
        return (
            <div className={classNames(styles['icon_cont'], styles['mr-6'])}>
                <p className={classNames(styles['button_3'], styles['white1'])} style={{ marginTop: 1 }}>{length}</p>
            </div>
        )
    }, [applied, selected])

    const updateSelected = useCallback((key: string) => {
        let obj = selected;
        if (key in obj) {
            delete obj[key];
        } else {
            obj[key] = key;
        }

        setSelected({ ...obj });
    }, [selected])

    const isSelected = useCallback((key: string) => {
        if (key in selected) {
            return true;
        } else {
            return false
        }
    }, [selected])

    const apply = useCallback(() => {
        if (Object.keys(selected).length > 0) {
            setApplied(true);
        } else {
            setApplied(false)
        }
        setOpen(false);
        onApply(selected)
    }, [onApply, selected])

    const clear = useCallback(() => {
        setOpen(false);
        onApply({})
        setSelected({})
        setApplied(false)
    }, [onApply])

    const modifiedData = useMemo(() => {
        if (config) {
            return config.filter((i) => i?.label !== 'Price');
        } else {
            return [];
        }

    }, [config])

    const renderItem = useCallback(() => {
        return (
            <>
                {modifiedData.map((data, index) => {
                    return (
                        <>
                            {listItem(data?.label, isSelected(data?.key), () => { updateSelected(data?.key) })}
                        </>
                    )
                })}
            </>
        )
    }, [isSelected, listItem, modifiedData, updateSelected])

    if (config?.length == 0) return <></>

    return (
        <div className={styles['extra_filter_container']}>
            <FilterItem leftIcon={rightComponent()} title={'Format'} selected={open || applied} onClick={() => { setOpen(!open) }} />
            {!isMobileView && (<>
                {open && <div className={styles['list_cont']}>
                    {renderItem()}
                    <div className={classNames(styles['borderBottom'], styles['mt-8'])} />
                    <div className={classNames(styles['row'], styles['align-center'], styles['justify-space-between'])}>
                        <p onClick={apply} className={classNames(styles['Body'], styles['blue1'], styles['mt-24'], styles['cursor_pointer'])}>Apply</p>
                        {Object.keys(selected).length > 0 && <p onClick={clear} className={classNames(styles['Body'], styles['white2_5'], styles['mt-24'], styles['cursor_pointer'])}>Clear</p>}
                    </div>
                </div>}
            </>)}
            {isMobileView && (<Modal show={open} onClose={() => { setOpen(!open) }}>
                <div>
                    <p className={classNames(styles['title_1'], styles['white1'], styles['mb-24'])}>Select Format</p>
                    {renderItem()}
                    <div className={classNames(styles['row'], styles['align-center'], styles['justify-space-between'], styles['mt-56'])}>
                        <div className={styles['width_']}>
                            <PrimaryButton buttonType={ButtonType.BORDER} text='Clear filter' onPress={clear} />
                        </div>
                        <div className={styles['width_']}>
                            <PrimaryButton text='Apply' onPress={apply} />
                        </div>
                    </div>
                </div>
            </Modal>)}
        </div>
    )
}

export default ExtraFilter;