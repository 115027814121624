export const customSort = (arr: any, compareFunction: ((arg0: any, arg1: any) => number) | undefined)=> {
    if (!Array.isArray(arr)) {
        throw new TypeError("Argument must be an array");
    }
    if (typeof compareFunction !== "function" && compareFunction !== undefined) {
        throw new TypeError("Comparator must be a function or undefined");
    }
    // Clone the array to avoid modifying the original
    const clonedArray = [...arr];
    // Implement the bubble sort algorithm
    for (let i = 0; i < clonedArray.length - 1; i++) {
        for (let j = 0; j < clonedArray.length - i - 1; j++) {
            // Use the custom compare function or default comparison
            const shouldSwap =
                typeof compareFunction === "function"
                    ? compareFunction(clonedArray[j], clonedArray[j + 1]) > 0
                    : String(clonedArray[j]) > String(clonedArray[j + 1]); // Default comparison as strings
            if (shouldSwap) {
                // Swap elements
                const temp = clonedArray[j];
                clonedArray[j] = clonedArray[j + 1];
                clonedArray[j + 1] = temp;
            }
        }
    }
    return clonedArray;
}