

import AxiosInstance, { getAxiosConfig } from "@/instance/instance";
import { Venue } from "@/routes/locate-venues/types";

export interface VenueListSportBody {
    sport: string,
    limit: number,
    skip: number,
    latLong: string[],
    city:string;
    area:string;
    venue_type: string[],
}

export interface Venues{
    position:number;
    venue_id:Venue;
}

export  interface SportVenueDataType{
    venues:Venue[];
    total_venues:number;
}
export interface SportVenueLitsDataType {
    data:SportVenueDataType;
    message:string;
    status:string;
}

export const getVenueListWithSportLoc = async (
    body: VenueListSportBody,
): Promise<SportVenueDataType> => {
    try {

        const response = await AxiosInstance.post(
            'v2/venue/web/venue_list',
            body,
            getAxiosConfig(false),
        );

        return response?.data?.data;
    } catch (error) {
        throw error;
    }
};
