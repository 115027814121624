

import AxiosInstance, { getAxiosConfig } from "@/instance/instance";
import { GameType } from "@/routes/game/type";

export interface AllCitiesType{
    _id: string;
    countryId: string;
    city: string;
    locality_name: string;
    coordinates: {
        type: string;
        coordinates: number[];
    };
    zones: string;
    status: true,
    created_at: string;
    modified_at: string;
    __v: number;
    sports: string[];
}
export interface GetOperatedCitiesDataType {
    data:{
        data:AllCitiesType[];
        message:string;
        status:string;
    }
 
}


export const getAllCities = async (): Promise<AllCitiesType[]> => {
    try {

        const response: GetOperatedCitiesDataType = await AxiosInstance.post(
            'v2/venue/get_city_list',
            {},
            getAxiosConfig(true),
        );
   
        return response?.data?.data;
    } catch (error) {
        throw error;
    }
};
