import React, { useMemo } from 'react'
import styles from './infoFooter.module.scss';
import classNames from 'classnames';
import Image from 'next/image';
import { capitalise } from '@/helper/capitalise';
import { pageType } from '@/routes/venues/venues';

interface InfoFooterPropTye {
    Pagetype: pageType;
    city: string;
    area: string;
    sport: string;
}
const InfoFooter = ({ city, area, sport, Pagetype }: InfoFooterPropTye) => {

    const { title1, desc1, desc1_5, title2, desc2 } = useMemo(() => {
        let title1, desc1, desc1_5, title2, desc2: string = '';
        if (Pagetype == pageType.AREA) {
            switch (sport) {
                case 'football':
                    title1 = `Your Premier Destination for Football in ${capitalise(area)}, ${capitalise(city)}!`;
                    desc1 = `Welcome to Turf Town, the ultimate hub for football enthusiasts in ${capitalise(area)}. We’re dedicated to providing unparalleled access to the beautiful game, offering a comprehensive listing of top-notch football venues right in your neighbourhood.`;
                    title2 = `Explore a Diverse Selection of Football Venues!`;
                    desc2 = `From pristine pitches to urban street courts, Turf Town curates a diverse selection of football venues scattered throughout ${capitalise(area)}. Whether you prefer the thrill of a competitive match or the camaraderie of a casual kickabout, our extensive network ensures there’s a perfect pitch waiting for you to lace up your boots and join in the action.`;
                    break;
                case 'badminton':
                    title1 = `Your Premier Destination for Badminton in ${capitalise(area)}, ${capitalise(city)}!`;
                    desc1 = `Welcome to Turf Town, the ultimate hub for badminton enthusiasts in ${capitalise(area)}. We’re dedicated to providing unparalleled access to this dynamic sport, offering a comprehensive listing of top-notch badminton venues right in your neighbourhood.`;
                    title2 = `Explore a Diverse Selection of Badminton Courts!`;
                    desc2 = `From sleek indoor courts to scenic outdoor settings, Turf Town curates a diverse selection of badminton venues scattered throughout ${capitalise(area)}. Whether you’re seeking intense rallies or friendly matches, our extensive network ensures there’s a perfect court waiting for you to grab your racquet and dive into the action.`;
                    break;
                case 'basketball':
                    title1 = `Your Premier Destination for Basketball in ${capitalise(area)}, ${capitalise(city)}!`;
                    desc1 = `Welcome to Turf Town, the ultimate hub for basketball enthusiasts in ${capitalise(area)}. Get ready to shoot hoops and slam dunk your way into the heart of the game as we provide unparalleled access to top-notch basketball courts right in your neighbourhood.`;
                    title2 = `Explore a Diverse Selection of Basketball Courts!`;
                    desc2 = `From urban street courts to sleek indoor facilities, Turf Town curates a diverse selection of basketball venues scattered throughout ${capitalise(area)}. Whether you’re a seasoned baller, a casual player, or just looking to enjoy a pickup game with friends, our extensive network ensures there’s a perfect court waiting for you to lace up your sneakers and showcase your skills on the hardwood.`;
                    break;
                case 'cricket':
                    title1 = `Your Premier Destination for Cricket in ${capitalise(area)}, ${capitalise(city)}!`;
                    desc1 = `Welcome to Turf Town, the ultimate hub for cricket enthusiasts in ${capitalise(area)}. We’re dedicated to providing unparalleled access to the gentleman’s game, offering a comprehensive listing of top-notch cricket venues right in your neighbourhood.`;
                    title2 = `Explore a Diverse Selection of Cricket Venues!`;
                    desc2 = `From traditional cricket grounds to innovative indoor facilities, Turf Town curates a diverse selection of cricket venues scattered throughout ${capitalise(area)}. Whether you’re a seasoned batsman, a cunning bowler, or a passionate fielder, our extensive network ensures there’s a perfect pitch waiting for you to don your whites and step onto the field.`;
                    break;

                case 'swimming':
                    title1 = `Your Premier Destination for Swimming in ${capitalise(area)}, ${capitalise(city)}!`;
                    desc1 = `Welcome to Turf Town, the ultimate hub for swimming enthusiasts in ${capitalise(area)}. Dive into a world of aquatic excitement as we provide unparalleled access to top-tier swimming pools right in your neighbourhood.`;
                    title2 = `Explore a Diverse Selection of Swimming Pools!`;
                    desc2 = `From Olympic-sized marvels to serene neighbourhood pools, Turf Town curates a diverse selection of swimming venues scattered throughout ${capitalise(area)}. Whether you’re a competitive swimmer, a leisurely lap enthusiast, or just looking to cool off on a hot day, our extensive network ensures there’s a perfect pool waiting for you to make a splash and enjoy the refreshing waters.`;
                    break;


                case 'pickleball':
                    title1 = `Your Premier Destination for Pickleball in ${capitalise(area)}, ${capitalise(city)}!`;
                    desc1 = `Welcome to Turf Town, the ultimate hub for pickleball enthusiasts in ${capitalise(area)}. Get ready to paddle, volley, and smash your way into the exciting world of pickleball as we provide unparalleled access to top-notch courts right in your neighbourhood.`;
                    title2 = `Explore a Diverse Selection of Pickleball Courts!`;
                    desc2 = `From indoor arenas to outdoor courts with stunning views, Turf Town curates a diverse selection of pickleball venues scattered throughout ${capitalise(area)}. Whether you’re a seasoned player or new to the game, our extensive network ensures there’s a perfect court waiting for you to grab your paddle and enjoy the fast-paced action of pickleball.`;
                    break;

                case 'padel':
                    title1 = `Your Premier Destination for Padel in ${capitalise(area)}, ${capitalise(city)}!`;
                    desc1 = `Welcome to Turf Town, the ultimate hub for Padel enthusiasts in ${capitalise(area)}. Get ready to paddle, volley, and smash your way into the exciting world of Padel as we provide unparalleled access to top-notch courts right in your neighbourhood.`;
                    title2 = `Explore a Diverse Selection of Padel Courts!`;
                    desc2 = `From indoor arenas to outdoor courts with stunning views, Turf Town curates a diverse selection of Padel venues scattered throughout ${capitalise(area)}. Whether you’re a seasoned player or new to the game, our extensive network ensures there’s a perfect court waiting for you to grab your paddle and enjoy the fast-paced action of Padel.`;
                    break;
                case 'squash':
                    title1 = `Your Premier Destination for Squash in ${capitalise(area)}, ${capitalise(city)}!`;
                    desc1 = `Welcome to Turf Town, the ultimate hub for Squash enthusiasts in ${capitalise(area)}. Get ready to paddle, volley, and smash your way into the exciting world of Squash as we provide unparalleled access to top-notch courts right in your neighbourhood.`;
                    title2 = `Explore a Diverse Selection of Squash Courts!`;
                    desc2 = `From indoor arenas to outdoor courts with stunning views, Turf Town curates a diverse selection of Squash venues scattered throughout ${capitalise(area)}. Whether you’re a seasoned player or new to the game, our extensive network ensures there’s a perfect court waiting for you to grab your paddle and enjoy the fast-paced action of Squash.`;
                    break;
                case 'tennis':
                    title1 = `Your Premier Destination for Tennis in ${capitalise(area)}, ${capitalise(city)}!`;
                    desc1 = `Welcome to Turf Town, the ultimate hub for Tennis enthusiasts in ${capitalise(area)}. Get ready to paddle, volley, and smash your way into the exciting world of Tennis as we provide unparalleled access to top-notch courts right in your neighbourhood.`;
                    title2 = `Explore a Diverse Selection of Tennis Courts!`;
                    desc2 = `From indoor arenas to outdoor courts with stunning views, Turf Town curates a diverse selection of Tennis venues scattered throughout ${capitalise(area)}. Whether you’re a seasoned player or new to the game, our extensive network ensures there’s a perfect court waiting for you to grab your paddle and enjoy the fast-paced action of Tennis.`;
                    break;

                default:
                    title1 = `Your Gateway to Sports in ${capitalise(area)}, ${capitalise(city)}!`;
                    desc1 = `Welcome to Turf Town, the ultimate hub for sports enthusiasts in ${capitalise(area)}. With just a tap, kickstart your game, as we offer unparalleled access to top-tier sports facilities right in your neighbourhood.`;
                    title2 = `Explore a Buffet of Sports!`;
                    desc2 = `Whether it’s cricket, football, badminton, basketball, or any other sport, our extensive network of venues ensures there’s something for everyone to savor in ${capitalise(area)}. Join us not only to play and train but also to connect with like-minded players across your local community. Together, let’s make sports a vibrant and inclusive part of ${capitalise(area)}’s identity!`;
                    break;
            }
        } else {
            switch (sport) {
                case 'football':
                    title1 = `Your Premier Destination for Football in ${capitalise(city)}!`;
                    desc1 = `Welcome to Turf Town, the ultimate hub for football enthusiasts in ${capitalise(city)}. We’re dedicated to providing unparalleled access to the beautiful game, offering a comprehensive listing of top-notch venues across the city for players of all skill levels to enjoy.`;
                    title2 = `Explore a Diverse Selection of Football Venues!`;
                    desc2 = `From pristine pitches to urban street courts, Turf Town curates a diverse selection of football venues scattered throughout ${capitalise(city)}. Whether you prefer the thrill of a competitive match or the camaraderie of a casual kickabout, our extensive network ensures there’s a perfect pitch waiting for you to lace up your boots and join in the action.`;
                    break;
                case 'badminton':
                    title1 = `Your Premier Destination for Badminton in ${capitalise(city)}!`;
                    desc1 = `Welcome to Turf Town, the ultimate destination for badminton enthusiasts in ${capitalise(city)}. We’re dedicated to providing unparalleled access to this dynamic sport, offering a comprehensive listing of top-notch venues across the city for players of all skill levels to enjoy.`;
                    title2 = `Explore a Diverse Selection of Badminton Courts!`;
                    desc2 = `From sleek indoor courts to scenic outdoor settings, Turf Town curates a diverse selection of badminton venues scattered throughout ${capitalise(city)}. Whether you’re seeking intense rallies or friendly matches, our extensive network ensures there’s a perfect court waiting for you to grab your racquet and dive into the action.`;
                    break;
                case 'cricket':
                    title1 = `Your Premier Destination for Cricket in ${capitalise(city)}!`;
                    desc1 = `Welcome to Turf Town, the ultimate hub for cricket enthusiasts in ${capitalise(city)}. We’re dedicated to providing unparalleled access to the gentleman’s game, offering a comprehensive listing of top-notch venues across the city for players of all skill levels to enjoy.`;
                    title2 = `Explore a Diverse Selection of Cricket Venues!`;
                    desc2 = `From traditional cricket grounds to innovative indoor facilities, Turf Town curates a diverse selection of cricket venues scattered throughout ${capitalise(city)}. Whether you’re a seasoned batsman, a cunning bowler, or a passionate fielder, our extensive network ensures there’s a perfect pitch waiting for you to don your whites and step onto the field.`;
                    break;

                case 'swimming':
                    title1 = `Your Premier Destination for Swimming in ${capitalise(city)}!`;
                    desc1 = `Welcome to Turf Town, the ultimate hub for swimming enthusiasts in ${capitalise(city)}. Dive into a world of aquatic excitement as we provide unparalleled access to top-tier swimming pools across the city.`;
                    title2 = `Explore a Diverse Selection of Swimming Pools!`;
                    desc2 = `From Olympic-sized marvels to serene neighbourhood pools, Turf Town curates a diverse selection of swimming venues scattered throughout ${capitalise(city)}. Whether you’re a competitive swimmer, a leisurely lap enthusiast, or just looking to cool off on a hot day, our extensive network ensures there’s a perfect pool waiting for you to make a splash and enjoy the refreshing waters.`;
                    break;
                case 'basketball':
                    title1 = `Your Premier Destination for Basketball in ${capitalise(city)}!`;
                    desc1 = `Welcome to Turf Town, the ultimate hub for basketball enthusiasts in ${capitalise(city)}. Get ready to shoot hoops and slam dunk your way into the heart of the game as we provide unparalleled access to top-notch basketball courts across the city.`;
                    title2 = `Explore a Diverse Selection of Basketball Courts!`;
                    desc2 = `From urban street courts to sleek indoor facilities, Turf Town curates a diverse selection of basketball venues scattered throughout ${capitalise(city)}. Whether you’re a seasoned baller, a casual player, or just looking to enjoy a pickup game with friends, our extensive network ensures there’s a perfect court waiting for you to lace up your sneakers and showcase your skills on the hardwood.`;
                    break;

                case 'pickleball':
                    title1 = `Your Premier Destination for Pickleball in ${capitalise(city)}!`;
                    desc1 = `Welcome to Turf Town, the ultimate hub for pickleball enthusiasts in ${capitalise(city)}. Get ready to paddle, volley, and smash your way into the exciting world of pickleball as we provide unparalleled access to top-notch courts across the city.`;
                    title2 = `Explore a Diverse Selection of Pickleball Courts!`;
                    desc2 = `From indoor arenas to outdoor courts with stunning views, Turf Town curates a diverse selection of pickleball venues scattered throughout ${capitalise(city)}. Whether you’re a seasoned player or new to the game, our extensive network ensures there’s a perfect court waiting for you to grab your paddle and enjoy the fast-paced action of pickleball.`;
                    break;

                case 'padel':
                    title1 = `Your Premier Destination for Padel in ${capitalise(city)}!`;
                    desc1 = `Welcome to Turf Town, the ultimate hub for Padel enthusiasts in ${capitalise(city)}. Get ready to paddle, volley, and smash your way into the exciting world of Padel as we provide unparalleled access to top-notch courts across the city.`;
                    title2 = `Explore a Diverse Selection of Padel Courts!`;
                    desc2 = `From indoor arenas to outdoor courts with stunning views, Turf Town curates a diverse selection of Padel venues scattered throughout ${capitalise(city)}. Whether you’re a seasoned player or new to the game, our extensive network ensures there’s a perfect court waiting for you to grab your paddle and enjoy the fast-paced action of Padel.`;
                    break;
                case 'squash':
                    title1 = `Your Premier Destination for Squash in ${capitalise(city)}!`;
                    desc1 = `Welcome to Turf Town, the ultimate hub for Squash enthusiasts in ${capitalise(city)}. Get ready to paddle, volley, and smash your way into the exciting world of Squash as we provide unparalleled access to top-notch courts across the city.`;
                    title2 = `Explore a Diverse Selection of Squash Courts!`;
                    desc2 = `From indoor arenas to outdoor courts with stunning views, Turf Town curates a diverse selection of Squash venues scattered throughout ${capitalise(city)}. Whether you’re a seasoned player or new to the game, our extensive network ensures there’s a perfect court waiting for you to grab your paddle and enjoy the fast-paced action of Squash.`;
                    break;
                case 'tennis':
                    title1 = `Your Premier Destination for Tennis in ${capitalise(city)}!`;
                    desc1 = `Welcome to Turf Town, the ultimate hub for Tennis enthusiasts in ${capitalise(city)}. Get ready to paddle, volley, and smash your way into the exciting world of Tennis as we provide unparalleled access to top-notch courts across the city.`;
                    title2 = `Explore a Diverse Selection of Tennis Courts!`;
                    desc2 = `From indoor arenas to outdoor courts with stunning views, Turf Town curates a diverse selection of Tennis venues scattered throughout ${capitalise(city)}. Whether you’re a seasoned player or new to the game, our extensive network ensures there’s a perfect court waiting for you to grab your paddle and enjoy the fast-paced action of Tennis.`;
                    break;

                default:
                    title1 = `Your doorway to sports in ${capitalise(city)}!`;
                    desc1 = `Welcome to Turf Town, a thriving community of sports enthusiasts dedicated to making sport accessible and enjoyable for all.`;
                    desc1_5 = `Discover top-tier sports facilities and tap into a vast network of local games happening right in your neighbourhood. Join us not only to play and train but also to connect with like-minded players across town. Together, let's make sports a vibrant and inclusive part of our community!`
                    title2 = `Dive into a Buffet of Sports!`;
                    desc2 = `Whether it's cricket, football, badminton, basketball, or any other sport, our extensive network of venues across the city ensures there's something for everyone to savour.`;
                    break;
            }
        }

        return { title1, desc1, desc1_5, title2, desc2 }
    }, [Pagetype, area, city, sport])

    return (
        <div className={styles['container']}>
            <div className={styles['sub_container']}>
                <div className={styles['left']}>
                    {title1 && <h2 className={classNames(styles['large_title1'], styles['white1'], styles['mb-20'])}>{title1}</h2>}
                    {desc1 && <p className={classNames(styles['para_text'], styles['mb-32'], !desc1_5 && styles['mb-56'])}>{desc1}</p>}
                    {desc1_5 && <p className={classNames(styles['para_text'], styles['mb-56'])}>{desc1_5}</p>}
                    {title2 && <h2 className={classNames(styles['large_title1'], styles['white1'], styles['mb-20'])}>{title2}</h2>}
                    {desc2 && <p className={classNames(styles['para_text'])}>{desc2}</p>}
                </div>
                <div className={styles['right']}>
                    <Image className={styles['img']} height={608} width={682} src={'/images/home_players.png'} alt='player images' />
                </div>
            </div>

        </div>
    )
}

export default InfoFooter