

import AxiosInstance, { UnAuthorizedError, axiosConfig, getAxiosConfig } from "@/instance/instance";
import { VenueData } from "@/routes/venue/types";

export interface CitySportsBodyType {
    city:string;
    area?:string;
}

export interface getVenueByNameResponse {
    data: {
        status: string;
        message: string;
        data: string[]
    }
}


export const getCitySports = async (
    body:CitySportsBodyType
): Promise<string[]> => {
    try {
        const response: any = await AxiosInstance.post(
            `v2/venue/city_sports`,
            body,
            getAxiosConfig(false),
        );
      
        return response?.data?.data;
    } catch (error) {
        throw error;
    }
};
