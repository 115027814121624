import React, { useCallback, useEffect, useState, useRef } from 'react';
import Image from 'next/image';
import { useRouter } from 'next/router';
import { motion } from 'framer-motion'
//styles
import styles from './sports.module.scss';
import classNames from 'classnames';
import { CitySportsBodyType, getCitySports } from '@/apis/getCitySports';
import SportsShimmer from './sportsShimmer/sportsShimmer';

// a. Football
// b. Badminton
// c. Cricket
// d. Pickleball
// e. Swimming
// f. Tennis
// g. Padel
// h. Basketball

const data = [
    { title: 'football', image: '/images/FootballImage.png' },
    { title: 'badminton', image: '/images/BadmintonImage.png' },
    { title: 'cricket', image: '/images/CricketImage.png' },
    { title: 'pickleball', image: '/images/pickleball.png' },
    { title: 'swimming', image: '/images/swimming.png' },
    { title: 'squash', image: '/images/squash.png' },
    { title: 'tennis', image: '/images/tennis.png' },
    { title: 'padel', image: '/images/padel.png' },
    { title: 'basketball', image: '/images/BasketballImage.png' },
];
export interface sportsPropType {
    selectedSport: string;
    onChange: (sport: string) => void;
    city: string;
    area: string;
    onData: (sport: string[]) => void;
}

const Sports = ({ selectedSport, onChange, city, area, onData }: sportsPropType) => {
    const router = useRouter();
    const scrollContainerRef = useRef<HTMLDivElement>(null);
    const [isMouseDown, setIsMouseDown] = useState(false);
    const [startX, setStartX] = useState(0);
    const [scrollLeft, setScrollLeft] = useState(0);
    const [AtStart, setStart] = useState<boolean>(true);
    const [AtEnd, setEnd] = useState<boolean>(false);
    const [sports, setSports] = useState<string[]>([])
    const [loading, setLoading] = useState<boolean>(true);

    const fetchSports = useCallback(async () => {
        try {
            let body: CitySportsBodyType = {
                city: city,
            }
            const res = await getCitySports(body);
            setSports(res);
            setLoading(false);
            onData && onData(res);
            setTimeout(() => {
                if (res?.length > 6) {
                    if (selectedSport) {
                        let index = data.findIndex(item => item.title === selectedSport);
                        const container = scrollContainerRef.current;
                        if (container && index > 6) {
                            container.scrollBy({
                                left: 174 * index, // Adjust scroll distance as needed
                                behavior: 'smooth', // Enable smooth scrolling
                            });
                        }
                    }
                }
            }, 400)


        } catch (e) {
            setLoading(false)
            console.error('fetch sports error', e)
        }
    }, [city, onData, selectedSport])

    useEffect(() => {
        fetchSports()

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    const getSportUrl = useCallback((sport: string) => {
        switch (sport) {
            case 'football':
                return 'football-turfs';
            case 'badminton':
                return 'badminton-courts';
            case 'cricket':
                return 'cricket-grounds';
            case 'basketball':
                return 'basketball-courts';
            case 'swimming':
                return 'swimming-pools';
            case 'pickleball':
                return 'pickleball-courts';
            case 'padel':
                return 'padel-courts';
            case 'squash':
                return 'squash-courts';
            case 'tennis':
                return 'tennis-courts';
            default:
                return '';
        }
    }, [])

    const handleClick = useCallback((sport: string) => {
        let currentPathname = router.asPath;
        const segments = currentPathname.split('/');
        if (segments.length == 3) {
            let lasturlSegment = segments[2].split('-');

            if (lasturlSegment[0] !== 'sports') {
                if (sport == lasturlSegment[0]) {

                    currentPathname = currentPathname.replaceAll(`${sport}-`, '');
                } else {

                    currentPathname = currentPathname.replaceAll(`${lasturlSegment[0]}`, `${sport}`);
                }
            } else {
                currentPathname = currentPathname.replaceAll(`sports`, `${sport}-sports`);

            }

        } else if (segments.length == 4) {
            let lasturlSegment = segments[3].split('-');
            if (lasturlSegment[0] !== 'sports') {
                if (sport == lasturlSegment[0]) {
                    currentPathname = currentPathname.replace(`${segments[3]}`, 'sports-venues-near-me');
                } else {
                    currentPathname = currentPathname.replace(`${lasturlSegment[0]}-${lasturlSegment[1]}`, `${getSportUrl(sport)}`);
                }
            } else {
                currentPathname = currentPathname.replace(`${segments[3]}`, `${getSportUrl(sport)}-near-me`);
            }
        }
        router?.replace(currentPathname, undefined)
    }, [getSportUrl, router])




    const change = useCallback((sport: string) => {
        handleClick(sport);
        if (selectedSport == sport) {
            onChange('')
        } else {
            onChange(sport)
        }
    }, [handleClick, onChange, selectedSport]);

    const renderItem = useCallback(() => {
        return (
            <>
                {data?.map((i, index) => {
                    let sport = sports?.find((sport) => sport == i?.title)
                    if (!sport) return <></>
                    return (
                        <div onClick={() => { change(i?.title) }} key={index} className={classNames(styles['mr-28'], styles['cursor-pointer'], styles['box_border'], selectedSport == i?.title && styles['active_border'], index == (sports.length - 1) && styles['mr-0'])}>
                            <div className={classNames(styles['image_item'])}>
                                <Image fill={true} alt={i?.title} src={i?.image} style={{ objectFit: "contain" }} className={classNames(styles['image'])} sizes="(max-width: 768px) 100vw, (max-width: 1200px) 50vw, 33vw" loading='eager' />
                            </div>
                        </div>
                    );
                })}
            </>
        );
    }, [change, selectedSport, sports]);



    const handleMouseDown = useCallback((e: React.MouseEvent) => {
        setIsMouseDown(true);
        if (scrollContainerRef.current) {
            setStartX(e.pageX - scrollContainerRef.current.offsetLeft);
            setScrollLeft(scrollContainerRef.current.scrollLeft);
        }
    }, []);

    const handleMouseLeave = useCallback(() => {
        setIsMouseDown(false);
    }, []);

    const handleMouseUp = useCallback(() => {
        setIsMouseDown(false);
    }, []);

    const handleMouseMove = useCallback((e: React.MouseEvent) => {
        if (!isMouseDown || !scrollContainerRef.current) return;
        e.preventDefault();
        const x = e.pageX - scrollContainerRef.current.offsetLeft;
        const walk = x - startX; // Adjust the scroll speed
        scrollContainerRef.current.scrollLeft = scrollLeft - walk;
    }, [isMouseDown, startX, scrollLeft]);

    const handleScroll = () => {
        const container = scrollContainerRef.current;
        if (container) {
            const { scrollLeft, scrollWidth, clientWidth } = container;
            const isAtStart = scrollLeft === 0;
            const isAtEnd = scrollLeft + clientWidth === scrollWidth;
            if (isAtStart && !AtStart) {
                setStart(true);
            } else if (!isAtStart && AtStart) {
                setStart(false);
            }

            if (isAtEnd && !AtEnd) {
                setEnd(true);
            } else if (!isAtEnd && AtEnd) {
                setEnd(false);
            }
            // You can perform further actions based on isAtStart and isAtEnd
        }
    };



    const handleScrollLeft = () => {
        let valueToScroll = 174
        const container = scrollContainerRef.current;

        if (scrollContainerRef.current) {
            const data = scrollContainerRef.current.getBoundingClientRect();
            if (AtEnd) {
                let componentsInView = Number((data?.width / 174));
                const decimalValue = (componentsInView % 1).toFixed(2);
                valueToScroll = (1 - Number(decimalValue)) * 174
                if (valueToScroll < 45) {
                    valueToScroll = valueToScroll + 174;
                }
            }
        }
        if (container) {
            container.scrollBy({
                left: -valueToScroll, // Adjust scroll distance as needed
                behavior: 'smooth', // Enable smooth scrolling
            });
        }
    };

    const handleScrollRight = () => {
        const container = scrollContainerRef.current;
        let valueToScroll = 174
        if (scrollContainerRef.current) {
            const data = scrollContainerRef.current.getBoundingClientRect();
            if (AtStart) {
                let componentsInView = Number((data?.width / 174));
                const decimalValue = (componentsInView % 1).toFixed(2);
                valueToScroll = (1 - Number(decimalValue)) * 174
                if (valueToScroll < 45) {
                    valueToScroll = valueToScroll + 174;
                }
            }
        }

        if (container) {
            container.scrollBy({
                left: valueToScroll, // Adjust scroll distance as needed
                behavior: 'smooth', // Enable smooth scrolling
            });
        }
    };

    if (loading) return <SportsShimmer />

    return (
        <div className={classNames(
            styles['container'],
        )}>
            {!AtEnd && sports?.length > 6 && <motion.div
                exit={{ transition: { duration: 0.7, ease: 'easeOut' } }}
                animate={{ transition: { duration: 0.7, ease: 'easeIn' } }}
                className={classNames(styles['arrow_cont'])} onClick={handleScrollRight}>
                <Image width={12} height={21} src={'/svgs/right-arrow.svg'} alt='arrow-icon' />
            </motion.div>}
            {!AtStart && <motion.div
                exit={{ transition: { duration: 0.7, ease: 'easeOut' } }}
                animate={{ transition: { duration: 0.7, ease: 'easeIn' } }}
                className={classNames(styles['arrow_cont_alt'])} onClick={handleScrollLeft}>
                <Image width={12} height={21} src={'/svgs/right-arrow.svg'} alt='arrow-icon' />
            </motion.div>}
            <div
                className={classNames(
                    styles['sub_container'],
                )}
                ref={scrollContainerRef}
                onMouseDown={handleMouseDown}
                onMouseLeave={handleMouseLeave}
                onMouseUp={handleMouseUp}
                onMouseMove={handleMouseMove}
                onScroll={handleScroll}
            >

                {renderItem()}
            </div>
        </div>

    );
};

export default Sports;
