import React, { useCallback, useMemo, useState } from 'react'
import Image from 'next/image'
import classNames from 'classnames'
import { useRouter } from 'next/router';
import { motion, AnimatePresence } from 'framer-motion';
//styles
import styles from './venueItemAlt.module.scss';
import { VenueItemPropType } from './type';
import { Venue } from '@/routes/locate-venues/types';
import { sportWhiteIcon } from '@/helper/sportWhiteicon';
import { showOfferTimings } from '@/helper/showOfferTimings';
import Link from 'next/link';


const VenueItemAlt = ({ data, index }: VenueItemPropType) => {
    const [onError, setError] = useState(false);
    const router = useRouter();

    const { href, as } = useMemo(() => {
        let name = data?.venue?.name.replaceAll('-', ' ')
        name = name.replaceAll(' ', '-').toLocaleLowerCase();
        let city = data?.venue_city?.city?.trim()?.replaceAll(' ', '-');
        let area = data?.venue?.area?.trim()?.replaceAll(' ', '-');
        let slug = `${name}-${area.toLocaleLowerCase()}-${data?.type.toLocaleLowerCase()}`;
        const specialCharactersRegex = /[^a-zA-Z0-9\s\-]/g;
        slug = slug.replaceAll('---', '-');
        slug = slug.replaceAll('--', '-');
        slug = slug.replaceAll(',', '');
        // Replace special characters with an empty string
        const cleanSlug = slug.replace(specialCharactersRegex, '');
        return { as: `/${city?.toLocaleLowerCase()}/sports-venue/${cleanSlug}`, href: `/${city?.toLocaleLowerCase()}/sports-venue/${cleanSlug}` }
    }, [data?.type, data?.venue?.area, data?.venue?.name, data?.venue_city?.city])

    const Types = (value: string) => {
        let x;
        if (
            value == '5s' ||
            value == '6s' ||
            value == '7s' ||
            value == '8s' ||
            value == '9s'
        ) {
            x = value.substring(0, 1) + ' v ' + value.substring(0, 1);
        } else if (value == '10s' || value == '11s' || value == '12s') {
            x = value.substring(0, 2) + ' v ' + value.substring(0, 2);
        } else if (value == 'semiOlympic') {
            return "Semi-Olympic";
        } else if (value == 'olympic') {
            return "Olympic";
        } else {
            switch (value) {
                case 'nonac':
                    x = 'Non AC';
                    break;
                case 'ac':
                    x = 'AC';
                    break;
                case 'hc':
                    x = 'Half Court';
                    break;
                case 'fc':
                    x = 'Full Court';
                    break;
                case 'net':
                    x = 'Net';
                    break;
                case 'ground':
                    x = 'Pitch';
                    break;
                default:
                    x = '';
            }
        }
        return x;
    };
    const renderGameSymbol = useCallback((type: string) => {
        switch (type) {
            case "football":
                return (
                    <div className={classNames(styles['row'], styles['align-center'], styles['mr-6'])}>
                        <Image className={styles['mr-3']} src={sportWhiteIcon(type)} width={14}
                            height={14} alt="" style={{ marginTop: -1 }} />
                        <p className={classNames(styles['type_title'], styles['white1'])}
                        >Football</p>
                    </div>
                )
            case "basketball":
                return (
                    <div className={classNames(styles['row'], styles['align-center'], styles['mr-6'])}>
                        <Image className={styles['mr-3']} src={sportWhiteIcon(type)} width={12}
                            height={12} alt="" style={{ marginTop: -1 }} />
                        <p className={classNames(styles['type_title'], styles['white1'])}
                        >Basketball</p>
                    </div>
                )
            case "badminton":
                return (
                    <div className={classNames(styles['row'], styles['align-center'], styles['mr-6'])}>
                        <Image className={styles['mr-3']} src={sportWhiteIcon(type)} width={12}
                            height={12} alt="" style={{ marginTop: -1 }} />
                        <p className={classNames(styles['type_title'], styles['white1'])}
                        >Badminton</p>
                    </div>
                )
            case "cricket":
                return (
                    <div className={classNames(styles['row'], styles['align-center'], styles['mr-6'])}>
                        <Image className={styles['mr-3']} src={sportWhiteIcon(type)} width={12}
                            height={12} alt="" style={{ marginTop: -1 }} />
                        <p className={classNames(styles['type_title'], styles['white1'])}
                        >Cricket</p>
                    </div>
                )
            case "swimming":
                return (
                    <div className={classNames(styles['row'], styles['align-center'], styles['mr-6'])}>
                        <Image className={styles['mr-3']} src={sportWhiteIcon(type)} width={12}
                            height={12} alt="" style={{ marginTop: -1 }} />
                        <p className={classNames(styles['type_title'], styles['white1'])}
                        >Swimming</p>
                    </div>
                )
            case "pickleball":
                return (
                    <div className={classNames(styles['row'], styles['align-center'], styles['mr-6'])}>
                        <Image className={styles['mr-3']} src={sportWhiteIcon(type)} width={12}
                            height={12} alt="" style={{ marginTop: -1 }} />
                        <p className={classNames(styles['type_title'], styles['white1'])}
                        >Pickleball</p>
                    </div>
                )

            case 'padel':
                return (
                    <div className={classNames(styles['row'], styles['align-center'], styles['mr-6'])}>
                        <Image className={styles['mr-3']} src={sportWhiteIcon(type)} width={12}
                            height={12} alt="" style={{ marginTop: -1 }} />
                        <p className={classNames(styles['type_title'], styles['white1'])}
                        >Padel</p>
                    </div>
                )

            case 'squash':
                return (
                    <div className={classNames(styles['row'], styles['align-center'], styles['mr-6'])}>
                        <Image className={styles['mr-3']} src={sportWhiteIcon(type)} width={12}
                            height={12} alt="" style={{ marginTop: -1 }} />
                        <p className={classNames(styles['type_title'], styles['white1'])}
                        >Squash</p>
                    </div>
                )

            case 'tennis':
                return (
                    <div className={classNames(styles['row'], styles['align-center'], styles['mr-6'])}>
                        <Image className={styles['mr-3']} src={sportWhiteIcon(type)} width={12}
                            height={12} alt="" style={{ marginTop: -1 }} />
                        <p className={classNames(styles['type_title'], styles['white1'])}
                        >Tennis</p>
                    </div>
                )


            default:
                return <></>;
        }
    }, []);

    const { offerText, coupon } = useMemo(() => {
        let offerText = '';
        let coupon = false;
        let offeerTiming = '';
        if (data?.offers && data?.offers?.length > 0) {
            let final_offer = data?.offers[0];
            offerText = final_offer.discount_type == "discountAmount"
                ? `Flat ${final_offer.discount} off`
                : `${final_offer.discount}% off`;
            if (final_offer.special_offer) {
                offerText = offerText + ' - Special offer';
            } else if (final_offer.start_time == "00:00" && final_offer.end_time == "24:00") {
                offerText = offerText + " on all slots ";
            } else {
                offerText = offerText + " from " + showOfferTimings(final_offer);
            }
            if (final_offer.discount_type === "discountAmount") {
                coupon = true;
            }
            else {
                coupon = false;
            }
        }

        return { offerText, coupon }

    }, [data?.offers])

    const { name, area } = useMemo(() => {
        let name, area = '';
        if (data?.venue?.name.length >= 21) {
            name = data?.venue?.name.substring(0, 18) + '...';
        } else if ((data?.venue?.name.length + data?.venue?.area?.length) < 22) {
            name = data?.venue?.name.trim() + ',';
            area = data?.venue?.area;
        }
        else {
            if ((21 - data?.venue?.name.length) > 5) {
                name = data?.venue?.name + `${(21 - data?.venue?.name.length) > 5 ? ',' : ''}`;
                area = data?.venue?.area.substring(0, 21 - data?.venue?.name.length).trim() + `${(21 - data?.venue?.name.length) > 5 ? '...' : ''}`;
            } else {
                name = data?.venue?.name;
            }

        }

        return { name, area };
    }, [data?.venue?.area, data?.venue?.name])

    return (
        <AnimatePresence>

            <motion.div

                initial={{ opacity: 0 }} // Initial opacity when component mounts
                animate={{ opacity: 1, transition: { duration: 0.7, ease: 'easeIn' } }} // Fade in or fade out animation
                exit={{ opacity: 0, transition: { duration: 0.7, ease: 'easeOut' } }}
                // onClick={handleClick} 
                className={classNames(styles['container'])}>
                <Link
                    className={styles['deco-none']}
                    href={href}
                    as={as}
                >
                    <div className={classNames(styles['image_item'])}>
                        <Image
                            priority={index == 0}
                            fill={true} style={{ objectFit: 'fill' }} className={styles['image']} src={onError ? '/svgs/VenueitemPlaceHolder.svg' : data?.venue?.venue_display_picture || data?.venue?.venue_cover_picture[0]} alt={`${data?.venue?.name}, ${data?.venue?.area}, ${data?.venue_city?.city}`} sizes="(max-width: 768px) 100vw, (max-width: 1200px) 50vw, 33vw" loading='eager' onError={() => { setError(true) }} />
                        <div className={styles["type_cont"]} style={{ paddingTop: 4 }}>
                            {renderGameSymbol(data?.type)}
                        </div>
                    </div>
                    <div className={classNames(styles['row'], styles['align-start'], styles['justify-space-between'], styles['mt-20'])} style={{ marginBottom: 16 }}>
                        <div className={classNames(styles['row'], styles['wd75'], styles['align-start'])}>
                            <p className={classNames(styles['venue_title'], styles['white1'], styles['mr-6'], styles['font18'])}>{name}<span className={classNames(styles['white2_5'], styles['venue_area_name'])}> {area}</span></p>
                        </div>
                        <div className={classNames(styles['row'], styles['align-center'])}>
                            <p className={classNames(styles['card_text_2'], styles['white2_5'], styles['mr-3'])}
                            >{data?.ratings_and_reviews?.rating ? data?.ratings_and_reviews?.rating.toFixed(1) : '--'}</p>
                            <Image className={styles['mb-1']} src={'/locatevenues/venueRatinf.png'} height={14} width={15} alt="sport image" />
                        </div>
                    </div>
                    <div className={classNames(styles['row'], styles['align-center'], styles['mb-24'])}>
                        {data?.configuration?.types?.map((i, index) => {
                            if (index < 3) {
                                return (
                                    <div key={index} className={classNames(styles['card'], styles['mr-8'])} style={{ background: "#272A2E" }}> <p className={classNames(styles['caption_2'], styles['white2'])}>{Types(i)}</p></div>
                                )
                            } else {
                                return <></>
                            }

                        })}
                        {data?.configuration?.types && data?.configuration?.types?.length > 0 && <div className={classNames(styles['dot_small'], styles['mr-12'])} />}
                        <p className={classNames(styles['card_text'], styles['white3'])}> <span style={{ fontSize: 16, }}>₹</span>  {data?.configuration?.avg_price ? data?.configuration?.avg_price : '--'} /  </p>
                        <Image src={'/locatevenues/perPerson.png'} height={12} width={11} alt="sport image" style={{ marginRight: 2, marginLeft: 4 }} />
                    </div>
                    {data?.offers && data?.offers?.length > 0 && (<div>
                        {<div className={classNames(styles['row'], styles['mt-8'], styles['align-center'], styles['borderTop'], styles['pt-16'], styles['mb-32'])}>
                            <Image src={coupon ? '/icons/coupon_icon.png' : '/icons/offer_icon.png'} height={17} width={17} alt="sport image" />
                            <p className={classNames(styles['offer_title'], styles['blue1'], styles['ml-8'], coupon && styles['coupon_green'])}>{offerText}</p>
                        </div>}
                    </div>)}

                </Link>
            </motion.div>

        </AnimatePresence >
    )
}

export default VenueItemAlt