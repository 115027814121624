import React, { useCallback } from 'react'
import styles from './LocalitieItem.module.scss'
import classNames from 'classnames';
import Image from 'next/image';
import Link from 'next/link';
import { useRouter } from 'next/router';

interface LocalitieItemPropType {
    title: string;
    venueCount: number;
    link: string;
}
const LocalitieItem = ({ title, venueCount, link }: LocalitieItemPropType) => {
    const router = useRouter();
    const handleClick = useCallback(() => {
        setTimeout(() => {
            router.reload()
        }, 550)
    }, [router]);

    return (
        <Link onClick={handleClick} className={classNames(styles['container'], styles['deco-none'])} href={link}>
            <div className={styles['text_cont']}>
                <p className={classNames(styles['font18'], styles['sub'], styles['white1'])}>{title}</p>
                {venueCount != 0 && <p className={classNames(styles['white3'], styles['caption_3'], styles['mt-6'])}>{venueCount} Venues</p>}
            </div>
            <Image height={12} width={12} src={'/svgs/right-arrow.svg'} alt='right-arrow' />
        </Link>
    )
}


export default LocalitieItem;
