

import AxiosInstance, { getAxiosConfig } from "@/instance/instance";
import { AreaType, CityAreasBodyType, getCityAreasResponse,  } from "../getCityAreas";


export interface NearByAreasBodyType {
    area:string;
}

export const getNearByLocalities = async (
    body:NearByAreasBodyType
): Promise<AreaType[]> => {
    try {
        const response: getCityAreasResponse = await AxiosInstance.post(
            `v2/venue/near_by_localities`,
            body,
            getAxiosConfig(false),
        );
      
        return response?.data?.data;
    } catch (error) {
        throw error;
    }
};
