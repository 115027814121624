

import AxiosInstance, { getAxiosConfig } from "@/instance/instance";
import { Venue } from "@/routes/locate-venues/types";

export interface getVenuesAroundYouBody {
    area?:string,
    city?:string,
    latLong: string[]; 
    limit: number; 
    skip: number, 
    sport: string[]
}
export interface getVenuesAroundYouDataType {
    data:{
        data:{
            final_venues:Venue[];
            all_venues:number;
        }
        message:string;
        status:string;
    }
}

export const getVenuesAroundYou = async (
    body: getVenuesAroundYouBody,
): Promise<getVenuesAroundYouDataType['data']> => {
    try {
       
        const response:getVenuesAroundYouDataType = await AxiosInstance.post(
            'v2/venue/web/venues_around_you',
            body,
            getAxiosConfig(false),
        );
        return response?.data;
    } catch (error) {
        throw error;
    }
};
