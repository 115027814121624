
import React, { useState } from "react";
import classNames from "classnames";
//styles
import styles from './radio.module.scss'
//type
import { RadioButtonType } from "./types";


const RadioButton = ({ onClick, value }: RadioButtonType) => {
    return (
        <div onClick={onClick} className={classNames(styles.container, value && styles['active_border'])} >
            {value && <div className={styles['inner_circle']} />}
        </div>
    );
};

export default RadioButton;