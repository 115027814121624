import moment from "moment";

export const showOfferTimings = (item: { start_time: string; end_time: string; }) => {
    let data = [];
    let data2 = [];
    if (item?.start_time == '00:00' && item?.end_time !== '24:00') {
      data.push('12 am');
    }
    if (item?.start_time !== '00:00' && item?.end_time == '24:00') {
      data2.push('12 am');
    }
    if (
      item?.start_time !== '00:00' &&
      parseInt(item?.start_time?.substring(3, 4)) > 0
    ) {
      data.push(
        moment(item?.start_time.split(':').join(''), 'Hmm').format('h:mm a'),
      );
    } else {
      data.push(moment(item?.start_time?.substring(0, 2), 'hours').format('h a'));
    }
    if (
      item?.end_time !== '24:00' &&
      parseInt(item?.end_time?.substring(3, 4)) > 0
    ) {
      data2.push(
        moment(item?.end_time?.split(':').join(''), 'Hmm').format('h:mm a'),
      );
    } else {
      data2.push(moment(item?.end_time?.substring(0, 2), 'hours').format('h a'));
    }
    if (item?.start_time == '00:00' && item?.end_time == '24:00') {
      return ``;
    } else {
      return `${data[0]} - ${data2[0]}`;
    }
  };