import React, { useCallback } from 'react'
import styles from '../sports.module.scss'
import classNames from 'classnames'


const data = [
    { title: 'football', image: '/images/FootballImage.png' },
    { title: 'badminton', image: '/images/BadmintonImage.png' },
    { title: 'cricket', image: '/images/CricketImage.png' },
    { title: 'basketball', image: '/images/BasketballImage.png' },
    { title: 'swimming', image: '/images/swimming.png' },
    { title: 'pickleball', image: '/images/pickleball.png' },
];

const SportsShimmer = () => {
    const renderItem = useCallback(() => {
        return (
            <>
                {data?.map((i, index) => {
                    return (
                        <div key={index} className={classNames(styles['mr-28'], styles['cursor-pointer'], styles['box_border'])}>
                            <div className={classNames(styles['image_item'], styles['shimmer_bg'])}>

                            </div>
                        </div>
                    );
                })}
            </>
        );
    }, []);

    return (
        <div
            className={classNames(
                styles['container'],
            )}
        >
            {renderItem()}
        </div>
    );
}

export default SportsShimmer