import React from 'react'
import styles from '../filters.module.scss'
import classNames from 'classnames'

const FilterShimmer = () => {
    return (
        <div className={classNames(styles['container'])}>
            <div className={classNames(styles['mb-16'])}>
                <div style={{ width: 255, height: 34, borderRadius: 10, background: '#202226' }}></div>
            </div>
            <div className={classNames(styles['row'], styles['align-center'], styles['margin_modification'], styles['mb-16'])}>
                <div style={{ width: 355, height: 34, borderRadius: 10, background: '#202226' }}></div>
            </div>
        </div>
    )
}

export default FilterShimmer