import React, { ReactNode, useCallback, useEffect, useState } from 'react';
//styles
import styles from './filters.module.scss';
import classNames from 'classnames';
import FilterItem from './filterItem';
import OfferIcon from '../../../../../public/svgs/offer';
import PriceIcon from '../../../../../public/svgs/price_icon';
import { motion, AnimatePresence } from 'framer-motion';
import { CollectionType } from '@/apis/getCollectionWithLoc';
import { Venue } from '@/routes/locate-venues/types';
import FilterShimmer from './filterShimmer/filterShimmer';

export interface filterPropType {
    title: string;
    onChange: (t: string) => void;
    children?: ReactNode;
    loading: boolean;
}

const Filters = ({ title, onChange, children, loading }: filterPropType) => {
    const [selected, setSelected] = useState('Distance');
    const [isSticky, setSticky] = useState(false);

    const isElementInViewport = (el: HTMLElement | null) => {
        const rect = el && el.getBoundingClientRect();

        if (rect) return (
            rect.top >= 0 &&
            rect.left >= 0 &&
            rect.bottom <= (window.innerHeight || document.documentElement.clientHeight) &&
            rect.right <= (window.innerWidth || document.documentElement.clientWidth)
        );
    }


    useEffect(() => {
        let sticky = false;
        const handleScroll = () => {
            const button = document.getElementById('filter_title')
            const isVisible = isElementInViewport(button);
            if (isVisible) {

                if (sticky) setSticky(false);
                sticky = false;
            } else {

                if (!sticky && button) setSticky(true);
                sticky = true;
            }
        };
        handleScroll();
        window.addEventListener('scroll', handleScroll);

        return () => {
            window.removeEventListener('scroll', handleScroll);
        };
    }, []);

    const loader = useCallback(() => {
        return (
            <AnimatePresence>
                <motion.div initial={{ opacity: 0.5 }} // Initial opacity when component mounts
                    animate={{ opacity: 1, transition: { duration: 0.3, ease: 'easeIn' } }} // Fade in or fade out animation

                    exit={{ opacity: 0, transition: { duration: 0.3, ease: 'easeOut' } }} >
                    <div style={{ width: 400, height: 30, borderRadius: 10, marginBottom: 20, background: '#202226' }} />
                </motion.div>
            </AnimatePresence>

        )
    }, [])

    if (loading) return (
        <motion.div
            initial={{ opacity: 0.5 }} // Initial opacity when component mounts
            animate={{ opacity: 1, transition: { duration: 0.3, ease: 'easeIn' } }} // Fade in or fade out animation
            exit={{ opacity: 0, transition: { duration: 0.3, ease: 'easeOut' } }}>
            <FilterShimmer />
        </motion.div>)


    return (
        <motion.div
            initial={{ opacity: 0 }} // Initial opacity when component mounts
            animate={{ opacity: 1, transition: { duration: 0.3, ease: 'easeIn' } }} // Fade in or fade out animation
            exit={{ opacity: 0, transition: { duration: 0.3, ease: 'easeOut' } }}
            className={classNames(styles['container'])}>

            <>
                {loading ? <>{loader()}</> : (<motion.h1
                    initial={{ opacity: 0 }} // Initial opacity when component mounts
                    animate={{ opacity: 1, transition: { duration: 0.3, ease: 'easeIn' } }} // Fade in or fade out animation
                    exit={{ opacity: 0, transition: { duration: 0.3, ease: 'easeOut' } }}
                    id="filter_title"
                    className={classNames(
                        styles['title_5'],
                        styles['white1'],
                        styles['mb-16']
                    )}
                    style={{ fontFamily: "Nexa-TextBold", fontSize: 22, textTransform: "capitalize" }}>
                    {title}
                </motion.h1>)}

                <motion.div
                    initial={{ opacity: 0 }} // Initial opacity when component mounts
                    animate={{ opacity: 1, transition: { duration: 0.3, ease: 'easeIn' } }} // Fade in or fade out animation
                    exit={{ opacity: 0, transition: { duration: 0.3, ease: 'easeOut' } }}
                    className={classNames(styles['row'], styles['align-center'], styles['margin_modification'], isSticky && styles['sticky'])}
                    style={{ marginRight: -20 }}>
                    {/* <FilterItem
                    title={'Relevance'}
                    selected={selected == 'Relevance'}
                    onClick={() => {
                        setSelected('Relevance');
                        onChange('Relevance');
                    }}
                /> */}
                    <FilterItem
                        title={'Distance'}
                        selected={selected == 'Distance'}
                        onClick={() => {
                            setSelected('Distance');
                            onChange('Distance');
                        }}
                    />
                    <FilterItem
                        leftIcon={
                            <div className={classNames(styles['mr-6'], styles['mt-4'])}>
                                {' '}
                                <OfferIcon fill={selected == 'Offers' ? '#E1E2E6' : '#7B818C'} />
                            </div>
                        }
                        title={'Offers'}
                        selected={selected == 'Offers'}
                        onClick={() => {
                            setSelected('Offers');
                            onChange('Offers');
                        }}
                    />
                    {children && children}

                    <FilterItem
                        title={'Price'}
                        selected={selected == 'Price'}
                        onClick={() => {
                            setSelected('Price');
                            onChange('Price');
                        }}
                        rightIcon={
                            <div className={classNames(styles['ml-8'], styles['mt-4'])}>
                                {' '}
                                <PriceIcon fill={selected == 'Price' ? '#E1E2E6' : '#7B818C'} />
                            </div>
                        }
                    />
                </motion.div >
            </>
        </motion.div>
    );
};

export default Filters;
