export const sportWhiteIcon = (type: string) => {
  switch (type) {
    case 'football':
      return '/whitesportsicon/football_white.png';
    case 'badminton':
      return '/whitesportsicon/badmintonapi.png';
    case 'cricket':
      return '/whitesportsicon/cricketapi.png';
    case 'basketball':
      return '/whitesportsicon/basketballapi.png';
    case 'swimming':
      return '/whitesportsicon/swimming_white.png';
    case 'pickleball':
      return '/whitesportsicon/pickleball_white.webp';
    case 'padel':
        return '/whitesportsicon/paddle_icon.png';
    case 'squash':
        return '/whitesportsicon/squash_icon.png';
    case 'tennis':
        return '/whitesportsicon/tennis_icon.png';
    default:
      return '/whitesportsicon/basketball_white.png';
  }
};
