

import AxiosInstance, { getAxiosConfig } from "@/instance/instance";
import { Venue } from "@/routes/locate-venues/types";
import { VenueListSportBody } from "../getVenueListWithSportLoc";

export interface configType{
    label:string;
    key:string;
}

export interface cityConfigYouDataType {
    data:{
        data:configType[];
        message:string;
        status:string;
    }
}

export const getVenuesCityConfig = async (
    body: VenueListSportBody,
): Promise<configType[]> => {
    try {
       
        const response:cityConfigYouDataType = await AxiosInstance.post(
            'v2/venue/venue_sport_format/',
            body,
            getAxiosConfig(false),
        );
      
        return response?.data?.data;
    } catch (error) {
        throw error;
    }
};
