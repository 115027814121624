import React from 'react'
import classNames from 'classnames'
//styles
import styles from './filters.module.scss'
//types
import { filterItemPropype } from './type'


const FilterItem = ({ title, leftIcon, rightIcon, selected, onClick }: filterItemPropype) => {
    return (
        <div onClick={onClick} className={classNames(styles['row'], styles['align-center'], styles['item_container'], styles['pb-18'], styles['mr-22'], selected && styles['active_border'], styles['cursor-pointer'])}>
            <div>
                {leftIcon}
            </div>
            <p className={classNames(styles['sub'], styles['text_grey'], selected && styles['white4'])} style={{fontFamily:"Nexa-Regular"}}>{title}</p>
            <div>
                {rightIcon}
            </div>
        </div>
    )
}

export default FilterItem